
import React, { useState } from "react";

import '../../../styles/Pages/home.css';
import SpotlightWrapper1 from '../../General/Carousels/SpotlightWrapper1';
import RotatableImage from "../../General/Image/RotatableImage";
import useWindowDimensions from "../../../utilities/hooks/windowDims";
import NormalButton, {NormalButtonStyle} from "../../General/Buttons/NormalButton";
import { useNavigate } from "react-router-dom";


const BASE_ROT_ANGLE = 50;
const FINAL_ROT_ANGLE = 0;


const spotlightItems = {
    "_01": {
        elem: <div style={{width: "100%", height: "100%"}}><img alt="" style={{width: '100%', height: '100%', objectFit: 'contain'}} src='/resources/Products/Observer_01/Features/UniquePatterns.gif'/></div>, 
        title: "Create patterns that utilize the geometric relationships of different shapes and rotations.",
        background: "\"/resources/Background/ForestSpheresO.png\"",
        backgroundColor: "var(--ayisen-forest",
    },
    "_02": {
        elem: <div style={{width: "100%", height: "100%"}}><img alt="" style={{width: '100%', height: '100%', objectFit: 'contain'}} src='/resources/Products/Observer_01/Features/FineTunedSound.gif'/></div>,
        title: "Fine tune sounds with a variety of effects, including chance, reverse, retrigger with pitch decay, and spatial panning.",
        background: "\"/resources/Background/OrangeSpheresO.png\"",
        backgroundColor: "var(--ayisen-gold)",
    },
    "_03": {
        elem: <div style={{width: "100%", height: "100%"}}><img alt="" style={{width: '100%', height: '100%', objectFit: 'contain'}} src='https://ayisen-public-assets.s3.us-east-2.amazonaws.com/FallingAround.gif'/></div>,
        title: "Access a wide library of presets that showcase the full potential of geometric sequencing.",
        background: "\"/resources/Background/ForestSpheresO.png\"",
        backgroundColor: "var(--ayisen-forest",
    },

};

interface HomeAnimationsProps {

}

enum AnimationPhase {
    RotateIn, 
    Spotlight,
};


export const HomeAnimationsMobile = (props: HomeAnimationsProps) => {

    const [ activeIdx, setActiveIdx ] = useState(0);
    const [ pannel1Rot, setPannel1Rot ] = useState(BASE_ROT_ANGLE);

    const navigate = useNavigate();

    const {width: windowWidth, height: windowHeight} = useWindowDimensions();

    const PIXELS_FOR_FULL_ROT = windowWidth < 800 ? 100 : 600;



    return <div className="HomeAnimationsMobile">

        <div className="homeRotatableMobile">

            <div className="spacerTop"/>

            <div className='innerAligner' style={{width: '100%', maxWidth: 1000, position: 'relative'}}>

                <div>

                    <h1>Observer_01</h1>

                    <h2>
                        Bespoke rhythm generation. Non-linear looping. Atmospheric patterns. Endless possibilities.
                    </h2>

                    <div className="homeButtonWrapper">
                        <NormalButton 
                            text = {"Shop now >"}
                            onClick = {() => navigate('product/observer_01')}
                            style = {NormalButtonStyle.CremeTextCremeBorder}
                            blockWidenForMobile = {true}
                            slim = {true}
                        />
                    </div>


                </div>

                <div className="homeRotImgWrapper">
                    <RotatableImage 
                        src = {'resources/Products/Observer_01/Images/CollisionEditor.png'}
                        angle = {pannel1Rot}
                    />
                </div>
                

            </div>

            <div className="spacerBottom"/>


        </div>

        
        <div className="homeSpotlight">
            <SpotlightWrapper1 
                spotlightItems={spotlightItems}
                activeIdx = {activeIdx}
                setActiveIdx = {setActiveIdx}
            />
        </div>

        


    </div>


}

export default HomeAnimationsMobile;