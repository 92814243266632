
import React, { useState } from "react";

import '../../../styles/Pages/home.css';
import ScrollCapture from "../../Utility/ScrollCapture";
import SpotlightWrapper1 from '../../General/Carousels/SpotlightWrapper1';
import RotatableImage from "../../General/Image/RotatableImage";
import useWindowDimensions from "../../../utilities/hooks/windowDims";
import NormalButton, {NormalButtonStyle} from "../../General/Buttons/NormalButton";
import { useNavigate } from "react-router-dom";

import PresetsGif from "../../../assets/SomePresets.gif";


const BASE_ROT_ANGLE = 50;
const FINAL_ROT_ANGLE = 0;


const spotlightItems = {
    "_01": {
        elem: <div style={{width: "100%", height: "100%"}}><img alt="" style={{width: '100%', height: '100%', objectFit: 'contain'}} src='/resources/Products/Observer_01/Features/UniquePatterns.gif'/></div>, 
        title: "Create patterns that utilize the geometric relationships of different shapes and rotations.",
        background: "\"/resources/Background/ForestSpheresO.png\"",
        backgroundColor: "var(--ayisen-forest",
    },
    "_02": {
        elem: <div style={{width: "100%", height: "100%"}}><img alt="" style={{width: '100%', height: '100%', objectFit: 'contain'}} src='/resources/Products/Observer_01/Features/FineTunedSound.gif'/></div>,
        title: "Fine tune sounds with a variety of effects, including chance, reverse, retrigger with pitch decay, and spatial panning.",
        background: "\"/resources/Background/OrangeSpheresO.png\"",
        backgroundColor: "var(--ayisen-gold)",
    },
    "_03": {
        elem: <div style={{width: "100%", height: "100%"}}><img alt="" style={{width: '100%', height: '100%', objectFit: 'contain'}} src='https://ayisen-public-assets.s3.us-east-2.amazonaws.com/FallingAround.gif'/></div>,
        title: "Access a wide library of presets that showcase the full potential of geometric sequencing.",
        background: "\"/resources/Background/ForestSpheresO.png\"",
        backgroundColor: "var(--ayisen-forest",
    },

};

interface HomeAnimationsProps {

}

enum AnimationPhase {
    RotateIn, 
    Spotlight,
};


export const HomeAnimations = (props: HomeAnimationsProps) => {

    const [ activeIdx, setActiveIdx ] = useState(0);
    const [ phase, setPhase ] = useState(AnimationPhase.RotateIn);
    const [ pannel1Rot, setPannel1Rot ] = useState(BASE_ROT_ANGLE);

    const navigate = useNavigate();

    const {width: windowWidth, height: windowHeight} = useWindowDimensions();

    const PIXELS_FOR_FULL_ROT = windowWidth < 800 ? 100 : 600;


    /**
     * Function to be used for continuous scroll callback
     */
    const continuousScrollCallback = (deltaY: number) => {

        // Are we in the Rotate In? 
        if (phase === AnimationPhase.RotateIn) {


            // We need to roate the image in the rotation pannel!

            // # of pixels that correspond to 1deg of rotation
            let pixelsPerAngle = PIXELS_FOR_FULL_ROT / (Math.abs(FINAL_ROT_ANGLE - BASE_ROT_ANGLE));

            // # of degrees of rotation per 1 pixel
            let anglePerPixel = 1/pixelsPerAngle;

            let amountToRot = (-deltaY * anglePerPixel);


            

            // Otherwise... update the angle
            
                setPannel1Rot((angle) => {

                    let result = angle + amountToRot;

                    if (result > BASE_ROT_ANGLE)
                        result = BASE_ROT_ANGLE;
                    if (result < FINAL_ROT_ANGLE)
                        result = FINAL_ROT_ANGLE;

                    return (result);
                });
            

        }


    }

    const onGestureEnd = ( aggregateDeltaY: number) => {

        // If we're at the final rotation angle and we're trying to rotate more negative....
        if (pannel1Rot <= FINAL_ROT_ANGLE ) {

            // Switch the phase!
            setPhase(AnimationPhase.Spotlight);

        }


    }


    const incrementIndex = (inc: number) => {

        let toSet = (activeIdx + inc);
        if (toSet >= Object.values(spotlightItems).length) {
            toSet = Object.values(spotlightItems).length - 1;
        }

        // console.log(activeIdx, " + ", inc, " = ", toSet);

        if (toSet < 0) {

            toSet = 0;

            // Move phase!
            setPhase(AnimationPhase.RotateIn);
            setPannel1Rot(FINAL_ROT_ANGLE + 1);

        }

        setActiveIdx(toSet);

    }


    // Figure out what scrolls to use



    // Allow discrete scroll for spotlight
    let discreteScrollConfig = undefined;
    if (phase === AnimationPhase.Spotlight) {

        discreteScrollConfig = {
            scrollThreshold: (windowWidth < 800 ? 100 : 200),
            callback: (inc: number) => {

                incrementIndex(inc);
            }
        }

    }



    return <ScrollCapture
        allowScrollUpFromLock={() => {

            if (phase === AnimationPhase.RotateIn && pannel1Rot === BASE_ROT_ANGLE)
                return true;

            return false;

        }}
        allowScrollDownFromLock = {() => {

            if (phase === AnimationPhase.Spotlight) {
                const finalIdx = (Object.values(spotlightItems).length-1);
                // console.log("Active Idx: ", activeIdx, " / ", finalIdx);
                
            
                return (activeIdx >= finalIdx);
            }

            return false;
            
        }}

        onGestureEnd = {onGestureEnd}

        continuousScrollCallback = {(deltaY: number) => continuousScrollCallback(deltaY)}

        discreteScrollInfo = {discreteScrollConfig}
    >
    
    <div className="HomeAnimations">


        <div className={"homeRotatable zeroOpacity" + (phase === AnimationPhase.RotateIn ? " fullOpacity" : "")}>

            <div className='innerAligner' style={{width: '100%', maxWidth: 1000, position: 'relative'}}>

                <div>

                    <h1>Observer_01</h1>

                    <h2>
                        Bespoke rhythm generation. Non-linear looping. Atmospheric patterns. Endless possibilities.
                    </h2>

                    <div className="homeButtonWrapper">
                        <NormalButton 
                            text = {"Shop now >"}
                            onClick = {() => navigate('product/observer_01')}
                            style = {NormalButtonStyle.CremeTextCremeBorder}
                            blockWidenForMobile = {true}
                            slim = {true}
                        />
                    </div>


                </div>

                <div className="homeRotImgWrapper">
                    <RotatableImage 
                        src = {'resources/Products/Observer_01/Images/CollisionEditor.png'}
                        angle = {pannel1Rot}
                    />
                </div>
                

            </div>


        </div>

        
        <div className={"homeSpotlight zeroOpacity" + (phase === AnimationPhase.Spotlight ? " fullOpacity" : "") }>
            <SpotlightWrapper1 
                spotlightItems={spotlightItems}
                activeIdx = {activeIdx}
                setActiveIdx = {setActiveIdx}
            />
        </div>

        


    </div>

    </ScrollCapture>



}

export default HomeAnimations;