
import { AllAddOnData, AllProductData, ProductData } from "../types";

import { Observer_01AddOnData, Observer_01Data } from "./Observer_01";
import { Root_01Data } from "./Root_01";
import { Ripple_01Data } from "./Ripple_01";
import { Go_01Data } from "./Go_01";


// Config of all product data
export const productUIData: AllProductData = {

    [Observer_01Data.uid]: Observer_01Data,

    [Root_01Data.uid]: Root_01Data,

    [Ripple_01Data.uid]: Ripple_01Data,

    [Go_01Data.uid]: Go_01Data,

};


// Config of all add-on data
export const addOnUIData: AllAddOnData = {

    'observer_01': Observer_01AddOnData,

}



export const injectAPIProductData = (apiProducts: any[]) => {


    // Get the original products and addOns
    let products = {...productUIData};
    let addOns = {...addOnUIData};

    // For each product from the API... 
    for (let apiProduct of apiProducts) {

        // Found in Product? 
        if (apiProduct.uid in products) {

            // Apply!
            products[apiProduct.uid].price = apiProduct.price;
            products[apiProduct.uid].salePrice = apiProduct.salePrice;
            products[apiProduct.uid].details = apiProduct;

        }

        // Find in addons... 
        for (let prodUID of Object.keys(addOns)) {

            // Found in Product?
            if (apiProduct.uid in addOns[prodUID]) {

                // Apply!
                addOns[prodUID][apiProduct.uid].price = apiProduct.price;
                addOns[prodUID][apiProduct.uid].salePrice = apiProduct.salePrice;
                addOns[prodUID][apiProduct.uid].details = apiProduct;

            }

        }

    }

    return {
        hybridProducts: products,
        hybridAddOns: addOns,
    }

}

