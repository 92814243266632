
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import api from '../../../api';

import "../../../styles/Pages/loginOps.css"
import NormalButton, { NormalButtonStyle } from '../../General/Buttons/NormalButton';
import utilities from '../../../utilities';
import GenericPageWrapper from '../GenericPageWrapper';
import { ModalState, ModalStateType } from '../../../constants/types';

export interface ConfirmEmailProps {

    setModalState: (val: ModalState) => any,

}

export const ConfirmEmail = (props: ConfirmEmailProps) => {

    const navigate = useNavigate();
    const params = useParams();


    const [ pendingConfirm, setPendingConfirm ] = useState(true);

    const [ errorMessage, setErrMessage ] = useState<string|null>(null);

    // On Mount 
    utilities.hooks.useEffectOnce(() => {

        confirmAccount();

    });

    const confirmAccount = async () => {

        if (!pendingConfirm)
            return;

        // Get params
        const email = params['email'];
        const accessCode = params['code'];
        if (!email || !accessCode)
            return setErrMessage("Invalid confirmation link.");

        // Confirm wiht the API
        const res = await api.get("user/confirmEmail/" + email + "/" + accessCode, {}, true);

        if (!res || !res.success) {

            if (res.message) {
                setErrMessage(res.message);
            }
            else {
                setErrMessage("Unable to verify your account. Please retry account creation, or contact our support team if the issue persists.");
            }
        }
        else {
            
        }

        setPendingConfirm(false);

    }

    // TODO @Marcel @Anna : Style

    return <GenericPageWrapper><div className='confirmEmailPage' id="confirmEmailPage">


        <div className='confirmEmailBody'>

            {(pendingConfirm && false) ? <>
                
                <h2>Confirming Account...</h2>
            
            </> : <>


                {(errorMessage && false) ? <>

                    <h2>Error confirming your account</h2>

                </> : <> 
            
                    <h2>Thank you, your account has been confirmed!</h2>

                    <div className='innerAligner confirmAccountButtonRow'>

                        <NormalButton
                            text='Login to continue'
                            onClick={() => props.setModalState({state: ModalStateType.Login})}
                            complexStyle = {{
                                style: NormalButtonStyle.HollowWithAccents,
                                background: "",
                                foreground: "var(--ayisen-blue)",
                            }}
                        />

                        <NormalButton
                            text='Return to home'
                            onClick={() => navigate('/')}
                            complexStyle = {{
                                style: NormalButtonStyle.HollowWithAccents,
                                background: "",
                                foreground: "var(--ayisen-midnight)",
                            }}
                        />

                    </div>
                </>}
            
            </>}
            
            {errorMessage && <p>
                {errorMessage}    
            </p>}

        </div>


        

    </div>
    </GenericPageWrapper>


}

export default ConfirmEmail;