
export const downloadLocal = (path: string, fileName: string) => {


    // Create Blob
    // let blob = new Blob([rawData], {type: mimeType});

    // Create an element to download from 
    let a = document.createElement('a');
    a.download = fileName;
    a.href = path;
    a.click();

    // Clean up
    window.URL.revokeObjectURL(a.href);

}

export default downloadLocal;