
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {toast} from 'react-toastify';

import "../../../styles/Pages/loginOps.css"
import utilities from '../../../utilities';
import api from '../../../api';
import NormalButton, { NormalButtonStyle } from '../../General/Buttons/NormalButton';
import StringInput from '../../General/String/StringInput';
import GenericPageWrapper from '../GenericPageWrapper';



export interface ReqestPassResetProps {
    setLoading: (loading: boolean) => any
}

export const RequestPassReset = (props: ReqestPassResetProps) => {

    const navigate = useNavigate();

    const [ email, setEmail ] = useState('');
    const [ linkSent, setLinkSent ] = useState(false);

    // Create new user
    const submit = async () => {

        const emailValid = utilities.verify.isValidEmail(email);

        if (!emailValid.status) {
            toast.error(emailValid.error);
            return;
        }

        props.setLoading(true);

        // Send api request!
        const res = await api.post('user/password/request-reset', {
            email: email,
        }, {}, true);

        props.setLoading(false);
        

        // Success? 
        if (res && res.success) {
            setLinkSent(true);
        }

    }


    const emailValid = utilities.verify.isValidEmail(email);


    return <GenericPageWrapper><div className='requestPassResetPage' id="requestPassResetPage">


        <div className='requestPassResetBody innerAligner'>

            {linkSent ? <>


                <h2>Success!</h2>


                <div className='inputSet'>

                    <p>An email has been send to {email}, with a link to reset your password.</p>

                    <NormalButton
                        text='Return to home'
                        onClick={() => navigate('/')}
                        style = {NormalButtonStyle.Dark1}
                        fillWidth = {true}
                    />

                </div>
            
            </> : <>
            
                <h2>Reset your password</h2>


                <div className='inputSet'>

                    <p>Please enter your email address. We’ll send you a link to create a new password via email.</p>

                    <StringInput 
                        value = {email}
                        editValue = {setEmail}
                        placeholder = "Your email"
                    />

                    <NormalButton
                        text='Email me my recovery link'
                        onClick={submit}
                        style = {NormalButtonStyle.Dark1}
                        fillWidth = {true}
                        disabled = {!emailValid.status}
                    />

                </div>
            
            
            </>}

        </div>

    </div></GenericPageWrapper>


}

export default RequestPassReset;