
import React, { useState } from 'react';
import {toast} from 'react-toastify';

import "../../../styles/Pages/loginOps.css"
import utilities from '../../../utilities';
import api from '../../../api';
import NormalButton, { NormalButtonStyle } from '../../General/Buttons/NormalButton';
import StringInput from '../../General/String/StringInput';
import CheckboxRow from '../../General/Buttons/CheckboxRow';
import { useNavigate } from 'react-router-dom';
import GenericPageWrapper from '../GenericPageWrapper';



export interface CreateAccountProps {
    setLoading: (val: boolean) => any,
}

export const CreateAccount = (props: CreateAccountProps) => {


    const [ firstName, setFirstName ] = useState('');
    const [ lastName, setLastName ] = useState('');
    const [ email, setEmail ] = useState('');
    const [ password, setPassword ] = useState('');
    const [ confirmPass, setConfirmPass ] = useState('');
    const [ agreeToTerms, setAgreeToTerms ] = useState(false);
    const [ optInUserTesting, setOptInUserTesting ] = useState(false);

    const navigate = useNavigate();


    const isPassMatch = () => {
        return password === confirmPass;
    }

    // Create new user
    const submit = async () => {

        const emailValid = utilities.verify.isValidEmail(email);
        const passValid = utilities.verify.isValidPassword(password);

        // Check validity
        if (!passValid.status) {
            toast.error(passValid.message);
            return;
        }

        if (!isPassMatch()) {
            toast.error('Passwords do not match!');
            return;
        }

        if (!emailValid.status) {
            toast.error(emailValid.error);
            return;
        }

        // Send api request!
        props.setLoading(true);
        const res = await api.post('user/create', {
            email: email,
            name: (firstName + " " + lastName),
            password: password,
            optInUserTesting: optInUserTesting ? true : false,
        }, {}, true);
        props.setLoading(false);
        

        // Error? 
        if (!res || !res.success) {
            toast.error(res.error);
            return;
        }

        // Otherwise, reroute!
        else {

            navigate("/email-pending");
        }


    }

    const getSubmissionError = () => {

        // Names
        if (!firstName || firstName.length <= 0)
            return "A first name is required.";
        if (!lastName || lastName.length <= 0)
            return "A last name is required.";

        // Email
        if (!email || email.length <= 0)
            return "An email is required.";
        const validEmail = utilities.verify.isValidEmail(email);
        if (!validEmail.status)
            return validEmail.error;

        // Password
        if (!password || password.length <= 0)
            return "A password is required";
        const validPass = utilities.verify.isValidPassword(password);
        if (!validPass.status)
            return validPass.message;
        if (!confirmPass || confirmPass.length <= 0)
            return "Please confirm your password.";
        if (password !== confirmPass)
            return "Passwords do not match";

        // Terms
        if (!agreeToTerms)
            return "Please agree to the terms and conditions.";

        // All good!
        return undefined;


    }

    let submissionError = getSubmissionError();
    let canSubmit = (submissionError === undefined);


    return <GenericPageWrapper><div className='createAccountPage fadeQuick innerAligner' id="createAccountPage">

        <h2>Create an account</h2>


        <div className='inputSet'>

            <p>Your account lets you authorize, download and manage Ayisen products plus your included library content.</p>


            <StringInput 
                value = {firstName}
                editValue = {setFirstName}
                placeholder = "First name"
            />

            <StringInput 
                value = {lastName}
                editValue = {setLastName}
                placeholder = "Last name"
            />

            <StringInput 
                value = {email}
                editValue = {setEmail}
                placeholder = "Your email"
                rightHandText='@'
            />

            <StringInput 
                value = {password}
                editValue = {setPassword}
                placeholder = "Password"
                isSecret = {true}
            />

            <StringInput 
                value = {confirmPass}
                editValue = {setConfirmPass}
                placeholder = "Confirm password"
                isSecret = {true}
            />

            {submissionError && <p className='formErrorMessage'>
                {submissionError}
            </p>}


            <CheckboxRow 
                text = {<div className='createAccountCheckboxText'>I agree with the <a href="/terms-and-conditions">Terms and Conditions</a></div>}
                value = {agreeToTerms}
                onChange = {setAgreeToTerms}
            />

            <CheckboxRow 
                text = {<div className='createAccountCheckboxText'>I would like to receive emails to participate in user research, in exchange for <b>discount codes</b></div>}
                value = {optInUserTesting}
                onChange = {setOptInUserTesting}
            />

            <div style={{height: "10px"}}/>

            
            <NormalButton
                disabled = {!canSubmit}
                text='Submit'
                onClick={submit}
                style = {NormalButtonStyle.Dark1}
                fillWidth = {true}
            />

        </div>

        

    </div></GenericPageWrapper>


}

export default CreateAccount;