
import { AllAddOnData, AllProductData, ProductData } from "../types";


export const Root_01Data: ProductData = {
    name : 'ROOT_01',
    uid: 'root_01',

    purchaseHero: {
        icon: "/resources/Products/Observer_1/purchaseHero.png",
        description: "Geometric orbital sequencer",
    },
    productFeatures: [

    ],

    banner : '/resources/Products/rootPlaceHolderPhoto.jpg',
    thumbnail : '/resources/Products/rootPlaceHolderPhoto.jpg',
    littleIcon : '/resources/AyisenLogo/IconBlack.png',

    description : 'LINEAR, THREAD DELAY.',

    staticPageLink : '/products/root_01',
    price : undefined,

};