import React from 'react';

import "../../../styles/Pages/legal.css";
import useEffectOnce from '../../../utilities/hooks/useEffectOnce';


export const CookiePolicyPage = () => {


    useEffectOnce(() => {
        // Load iubenda
        let s = document.createElement('script');
        let tag = document.getElementsByTagName('script')[0];
        s.src = "https://cdn.iubenda.com/iubenda.js";
        tag.parentNode?.insertBefore(s, tag);
    });



    return <div className='plainDocumentPage iubenda-page fadeQuick' id="plainDocumentPage">

        <div className='innerAligner plainDocumentTextContainer'>

        {/* <a href="https://www.iubenda.com/privacy-policy/43357085/cookie-policy" className="iubenda-white no-brand iubenda-noiframe iubenda-embed iubenda-noiframe iub-body-embed" title="Cookie Policy">Cookie Policy</a> */}

        {/* <a href="https://www.iubenda.com/privacy-policy/43357085/cookie-policy" className="iubenda-white iubenda-noiframe iubenda-embed iubenda-noiframe iub-body-embed" title="Cookie Policy">Cookie Policy</a> */}

        <a href="https://www.iubenda.com/privacy-policy/43357085/cookie-policy" className="iubenda-white iubenda-noiframe iubenda-embed iub-no-markup iubenda-noiframe iub-body-embed" title="Cookie Policy">Cookie Policy</a>

        </div>


    </div>


}

export default CookiePolicyPage;