
import React from 'react';

import '../../styles/Pages/home.css';
import { useEffect, useState } from 'react';

export interface ScrollFollowerProps {
    elemId: string,
    setScrollPercent: (val: number) => any,
    topOutPercent?: number,
}

/**
 *
 * A component that tracks the scrolling actions made in the window, w.r.t. a given element, and provides updates to the parent compoent via callback. 
 *  
 * @param props 
 * 
 *
 * 
 * @returns 
 */
export const ScrollFollower = (props: ScrollFollowerProps) => {

    const [ scrollTop, setScrollTop] = useState(0);
    
    // eslint-disable-next-line
    const [_, setScrolling ] = useState(false);

   

    useEffect(() => {

        const getScrollLoc = (topOutPercent = 0.6) => {

            const containerR = document.getElementById(props.elemId);
            if (!containerR)
                return 0;
    
            const containerRect = containerR.getBoundingClientRect();
    
            // Distance of conatiner top to screen bottom
            const screenBottom = window.outerHeight;
            const screenH = screenBottom;
    
            // Container is below the bottom of the screen? 
            if (containerRect.top > screenBottom)
                return 0;
    
            // Otherwise, the container is above the bottom of the screen!
            // Figure out what percentage of it is visible. 
            // I.e. what percentage of it is above the bottom of the screen. 
            let amountOnScreen = screenH*0.5 - containerRect.top;
            let amountWhenFullyOffScreenPast = containerRect.height + screenH;
    
            // #Tag: Enhancement: Some form of "Start" percent would be nice here.... 
            // Something that can make it so that the value is zero, until you hit a certain location
    
            // The top out percent will scale the "threshold at which we consider 100% scrolled", such that if the top out is 0.5,... we'll consider the scroll complete when we're half way through. 
            amountWhenFullyOffScreenPast *= topOutPercent;
    
            let percent = (amountOnScreen / amountWhenFullyOffScreenPast) + 0.2;
    
            if (percent < 0)
                percent = 0
            if (percent > 1)
                percent = 1;
    
            props.setScrollPercent(percent);
    
            return percent;
    
        }


        const onScroll = (e: any) => {
          setScrollTop(e.target.documentElement.scrollTop);
          setScrolling(e.target.documentElement.scrollTop > scrollTop);
          getScrollLoc(props.topOutPercent);
        };
        window.addEventListener("scroll", onScroll);
    
        return () => window.removeEventListener("scroll", onScroll);
      }, [scrollTop, props, props.topOutPercent]);

    


    return <div style={{display: 'none'}}>

    </div>


}

export default ScrollFollower;