
export const blockScrolling = () => {

    // Block scrolling
    // document.body.style.overflow = "hidden";
   
    document.body.classList.add('screenLock');

}

export const enableScrolling = () => {

    // Allow scrolling
    // document.body.style.overflow = "auto";
    document.body.classList.remove('screenLock');

}


const preventDefault = (e: any) => {

    e = e || window.event
    if (e.preventDefault) {
        e.preventDefault()
    }
    e.returnValue = false

}


export const blockWheel = () => {

    document.addEventListener("wheel", preventDefault, {passive: false});
    
}

export const unblockWheel = () => {
    document.removeEventListener('wheel', preventDefault, false);
}


export const outputs = {
    blockScrolling,
    enableScrolling,
    blockWheel, 
    unblockWheel,
}

export default outputs;