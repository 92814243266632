
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import api from '../../api';
import { Cart, ModalState, ModalStateType, User } from '../../constants/types';

import '../../styles/header.css';
import utils from '../../utilities';
import useWindowDimensions from '../../utilities/hooks/windowDims';

import UserInfoDrop from '../General/User/UserInfoDrop';
import constants from '../../constants';


export interface HeaderProps {

    cart: Cart,

    user?: User|null,

    setModalState: (state: ModalState|null) => any,

};


interface LinkData {
    display: string, 
    link?: string, 
    showCartCount?: boolean,
    hideIfLoggedIn?: boolean,
    modalState?: ModalStateType,
}

const links: LinkData[] = [

    {
        display: 'Cart',
        link: '/cart',
        showCartCount: true,
    },
    {
        display: 'Log in',
        hideIfLoggedIn: true,
        modalState: ModalStateType.Login,
    },
    {
        display: 'About',
        link: '/about',
    }, 
    {
        display: 'Observer_01',
        link: '/product/observer_01',
    }

]



export const Header = (props: HeaderProps) => {

    // Navagator Open
    const [navOpen, setNavOpen] = useState(false);

    // User Info Open
    const [userInfoOpen, setUserInfoOpen] = useState(false);
    const [ inLightZone, setInLightZone ] = useState(false);

    const navigate = useNavigate();
    const {width: windowWidth, height: windowHeight} = useWindowDimensions();

    const toHome = () => {
        setNavOpen(false);
        navigate("/");
    }

    const toLink = (link: string) => {
        setNavOpen(false);
        navigate(link);
    }

    const toggleNav = async () => {
        setNavOpen(!navOpen);
    }


    const logout = async () => {

        await api.get('user/logout');
        window.location.href = '/';

    }


    const linkDataClick = async (data: LinkData) => {

        if (data.link) {
            toLink(data.link);
        }

        if (data.modalState || data.modalState === 0) {
            props.setModalState({state: data.modalState});
        }

        setNavOpen(false);

    }

    // On Mount 
    useEffect(() => {

        const onScroll = () => {

            // Check if the top most component is branded as specifically light
            let inLight = false;
            for (let id of constants.general.lightModeComponentIds) {

                // Get 
                const elem = document.getElementById(id);
                if (!elem)
                    continue;

                // Check if it's at the top of the screen
                const boundingBox = elem.getBoundingClientRect();

                // Consuming the "0" zone of the screen? 
                if (boundingBox.top < 1 && boundingBox.bottom > 0) {
                    inLight = true;
                    break;
                }

            }


            if (inLightZone !== inLight)
                setInLightZone(inLight);


        }

        // Baseline
        onScroll();

        window.addEventListener("scroll", onScroll);
    
        return () => {

            window.removeEventListener("scroll", onScroll);

        }

    })


    // Root Path We're At
    const pathRoot = '/' + window.location.pathname.split('/')[1];

    // Cart Count
    let cartCount = 0;
    for (let item of props.cart) 
        cartCount += item.count;

    return <div className={"Header" + (inLightZone ? " headerOverLight" : "")}>

        {/* Logo */}
        <div className='Logo'>
                <img className='LogoText overDarkOnly' src={'/resources/AyisenLogo/' + (windowWidth > 800 ? 'LogoCreme.png' : 'IconCreme.png')} alt='AYISEN' onClick={toHome}/>
                <img className='LogoText overLightOnly' src={'/resources/AyisenLogo/' + (windowWidth > 800 ? 'LogoBlue.png' : 'IconBlue.png')} alt='AYISEN' onClick={toHome}/>
                
        </div>


        {/* User Options */}
        {props.user ? <>
            <img className='HeaderOption HeaderIcon imgInvert' src={utils.user.getAvatar(props.user)} alt='User' onClick={() => {
                if (!userInfoOpen)
                    setUserInfoOpen(true);
            }} />
            <UserInfoDrop 
                user={props.user}
                hide={!userInfoOpen} 
                close={() => setUserInfoOpen(false)}
            />
        </>
        : <></>
        }


        {/* Links */}
        {links.map( (linkData) => {

            if (props.user && linkData.hideIfLoggedIn)
                return <></>

            return <p 
                className={"HeaderOption" + (pathRoot === linkData.link ? ' HeaderActive' : '')} 
                onClick={()=>linkDataClick(linkData)} 
                style={{position: 'relative'}}
            >
                    {/* Text */}
                    {linkData.display}

                    {/* Cart Count if needed */}
                    {linkData.showCartCount && cartCount > 0 && <div className='cartCount'>{cartCount}</div>}
            </p>
        })}

        {/* Nav Opener */}
        <div className='headerOpener'>
            <img className='overDarkOnly'  src='/resources/Icons/nav.png' alt='Nav'  onClick={toggleNav}/>
            <img className='overLightOnly'  src='/resources/Icons/navBlue.png' alt='Nav'  onClick={toggleNav}/>
        </div>

        {/* Side Nav */}
        <div className={'navBar' + (navOpen ? ' navBarOpen' : '')}>

            {/* Standard Links */}
            {links.map( (linkData, idx) => {

                if (props.user && linkData.hideIfLoggedIn)
                    return <></>

                return <div className={'opt' + (pathRoot === linkData.link ? ' optActive' : '')}
                            onClick={()=>linkDataClick(linkData)}
                        >
                    {linkData.display}

                </div>
            } )}

            {/* Login / Account Settings */}
            {props.user && props.user.email ? <>
                <div className='opt' onClick={() => navigate('/my-account')}>My Account</div>
                <div className='opt' onClick={logout}>Logout</div>
            </> : <></>}

            
        </div>

        {navOpen && 
            <div className='navBarExiter' onClick={()=>setNavOpen(false)}/>
        }

    
    </div>

}


export default Header;