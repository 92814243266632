
import React, { useState } from 'react';

import '../../../styles/General/Product/presetCarosel.css'
import { Cart, ProdAddOnData } from '../../../constants/types';
import PresetCard from './PresetCard';

import ItemsCarousel from 'react-items-carousel';



export interface PresetCaroselProps {

    addOns: ProdAddOnData[],
    cart: Cart,
    setCartCount: (productUid: string, count: number) => any,

}

export const PresetCarosel = (props: PresetCaroselProps) => {

    const [activeIndex, setActiveIndex] = useState(0);

    return <div>

        {/* Display for Browser */}
        <div className='browserOnly'>

            <ItemsCarousel
                infiniteLoop={true}
                gutter={32}
                activePosition={'center'}
                chevronWidth={60}
                disableSwipe={false}
                alwaysShowChevrons={false}
                numberOfCards={3}
                slidesToScroll={2}
                outsideChevron={true}
                showSlither={true}
                firstAndLastGutter={false}
                activeItemIndex={activeIndex}
                requestToChangeActive={setActiveIndex}
                rightChevron={">"}
                leftChevron={"<"}
            >

            {props.addOns.map((addOn, idx) => {

                const cartItem = props.cart.find(i => i.uid === addOn.uid);

                return <PresetCard 
                    addOn = {addOn}
                    setCartCount = {(v) => props.setCartCount(addOn.uid ?? "", v)}
                    cartCount = {cartItem?.count ?? 0}
                />

            })}



            </ItemsCarousel>

        </div>

        {/* Display For Mobile */}
        <div className='mobileOnly'>

            <ItemsCarousel
                infiniteLoop={false}
                gutter={32}
                activePosition={'center'}
                chevronWidth={60}
                disableSwipe={false}
                alwaysShowChevrons={false}
                numberOfCards={1}
                slidesToScroll={1}
                outsideChevron={false}
                showSlither={false}
                firstAndLastGutter={false}
                activeItemIndex={activeIndex}
                requestToChangeActive={setActiveIndex}
                rightChevron={">"}
                leftChevron={"<"}
            >

            {props.addOns.map((addOn, idx) => {

                const cartItem = props.cart.find(i => i.uid === addOn.uid);

                return <PresetCard 
                    addOn = {addOn}
                    setCartCount = {(v) => props.setCartCount(addOn.uid ?? "", v)}
                    cartCount = {cartItem?.count ?? 0}
                />

            })}



            </ItemsCarousel>

        </div>



    </div>
    
    
}

export default PresetCarosel;