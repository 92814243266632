

import React, { useState } from 'react';
import { toast } from 'react-toastify';


import '../../../styles/General/stringValEditor.css'
import NormalButton, { NormalButtonStyle } from '../Buttons/NormalButton';
import StringInput from './StringInput';

interface SaveChangesData {
    value: string,
    oldValue?: string, 
    confirmValue?: string,
};

export interface StringValEditorProps {

    value: string,
    updateValue: (data: SaveChangesData) => any,

    isSecret?: boolean,

    valuePlaceholderText?: string,

    confirmationPreviewText?: string,

    requireOldValPreviewText?: string,

}

export const StringValEditor = (props: StringValEditorProps) => {


    const [ inEdit, setInEdit ] = useState(false);
    const [ modifiedValue, setModValue ] = useState<string|null>(null);
    const [ oldValConfirm, setOldValConfirm ] = useState('');
    const [ confirmVal, setConfirmVal ] = useState('');

    const enterEdit = () => {
        setInEdit(true);
    }

    const revertEdits = () => {
        setInEdit(false)
        setModValue(null);
        setOldValConfirm('');
        setConfirmVal('');
    }

    const saveChanges = async () => {

        // Make sure the confirmation matches. 
        if (!doConfirmValsMatch())
            return toast.error("The input value does not match the confirmation value.");

        // Send all the data up the chain, and revert edits!
        await props.updateValue({
            value: modifiedValue ?? "", 
            confirmValue: confirmVal,
            oldValue: oldValConfirm,
        });

        revertEdits();

    }

    const doConfirmValsMatch = () => {
        if (props.confirmationPreviewText)
            return (confirmVal === modifiedValue);
        
        // Otherwise... pass
        else {
            return true;
        }
    }


    const confirmValsMatch = doConfirmValsMatch();

    return <div className="stringValEditor">


        {/* In Edit, and  old required? */}
        {inEdit && props.requireOldValPreviewText &&  <div className='inputWrapper'>
            <StringInput
                value = {oldValConfirm}
                editValue = {(v) => setOldValConfirm(v)}
                disabled = {!inEdit}
                isSecret = {props.isSecret}
                placeholder = {props.requireOldValPreviewText}
            />
        </div>}

        {/* Main Input */}
        <div className='inputWrapper'>
            <StringInput
                value = {modifiedValue ?? props.value}
                editValue = {(v) => setModValue(v)}
                disabled = {!inEdit}
                isSecret = {props.isSecret}
                placeholder = {props.valuePlaceholderText}
            />
        </div>

        {/* In Edit, and Confrim? */}
        {inEdit && props.confirmationPreviewText && <div className='inputWrapper'>
            <StringInput
                value = {confirmVal}
                editValue = {(v) => setConfirmVal(v)}
                disabled = {!inEdit}
                isSecret = {props.isSecret}
                placeholder = {props.confirmationPreviewText}
            />
        </div>}

        {/* Multiple Editors When In Edit? */}

        {/* Button For Unlock / Save */}
        {!inEdit && <>
            <NormalButton 
                text = {'Edit'}
                onClick = {enterEdit}
                style = {NormalButtonStyle.Dark1}
            />
        </>}

        {inEdit && <div className='buttonRow'>
            <NormalButton 
                text = {'Revert'}
                onClick = {revertEdits}
                style = {NormalButtonStyle.LightWithBorder1}
            />
            <NormalButton 
                text = {'Save new'}
                onClick = {saveChanges}
                style = {NormalButtonStyle.Dark1}
                disabled = {!confirmValsMatch}
            />
        </div>}
        

    </div>


}

export default StringValEditor;