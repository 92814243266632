
import React from 'react';
import { useParams } from 'react-router-dom';
import Scrollbars from 'react-custom-scrollbars';
import Scrollbars2 from 'react-custom-scrollbars-2';

import '../../styles/Pages/productPage.css'

import ProductComponents from '../General/Product';
import { AllProductData, AllAddOnData, Cart, ProductData, CartItem, ProdAddOnData, ModalState, User } from '../../constants/types';
import FeatureCardSet from '../General/Product/FeatureCardSet';
import ReactPlayer from 'react-player';
import General from '../General';
import useEffectOnce from '../../utilities/hooks/useEffectOnce';
import FeatureCard from '../General/Product/FeatureCard';



export interface ProductPageProps {

    products: AllProductData,
    addOns: AllAddOnData,
    cart: Cart,
    setCartCount: (productUid: string, count: number) => any,
    setModalState: (state: ModalState) => any,
    setLoading: (val: boolean) => any,
    user: User|null,


}


const ProductPage = (props: ProductPageProps) => {

    const params = useParams();


    useEffectOnce(() => {

        // Scroll to top of page!
        window.scrollTo({top: 0, behavior: 'smooth'});

    });


    const goToSystemRequirements = () => {

        const systemRequirements = document.getElementById('systemRequirements');
        systemRequirements?.scrollIntoView({block: 'start', behavior: 'smooth'});


    }



    // Locate Product Data
    const productUID = params['product_uid']

    let product: ProductData|undefined = undefined;
    let cartItem: CartItem|undefined = undefined;
    let addOns: ProdAddOnData[]|undefined = undefined;

    if (productUID) {
        product = props.products[productUID];
        cartItem = props.cart.find(i => i.uid === productUID);
        addOns = Object.values(props.addOns[productUID]);
    }
    

    // #Tag: Enhancement :: Pull the system requirements from the current product details (no need with just Observer)
    // let version: any;
    // let systemRequirements: {title: string, bullets: string[]}[] = [];

    // if (product?.details?.versions && product?.details?.versions.length > 0) {
    //     version = product.details.versions[product.details.versions.length -1];
    //     systemRequirements = version.systemRequirements;
    // }

    // #Tag: Deployment: Finalize the product system requirements

    return <div id='productPage' className="productPage fadeQuick">

        

        {/* <div className='innerAligner'> */}

            {/* Exists? */}
            {product && <>

                {/* Product Purchase Hero */}
                <ProductComponents.PurchaseHero 
                    product={product}
                    cartCount = {cartItem?.count ?? 0}
                    setCartCount = {(v) => props.setCartCount(productUID ?? '', v)}
                    goToSystemRequirements = {goToSystemRequirements}
                    setModalState = {props.setModalState}
                    user = {props.user}
                    setLoading = {props.setLoading}
                />

                <div className='bluePageSection'>

                    <div className='innerAligner productPageBody'>

                        <div className='pageSection productPage-LeftRightSection '>

                            <h2 style={{padding: "60px 0px"}}>Here's how to get the most out of Observer_01</h2>

                            <div style={{width:"100%", marginLeft: 0, marginRight: 0}}>
                                <div className='innerAligner videoWrapper'>
                                    <ReactPlayer 
                                        // ref='youtubePlayer'
                                        width='100%'
                                        height= "100%"
                                        url='https://www.youtube.com/watch?v=g5VAFOCP9IQ&ab_channel=Ayisen'
                                        controls = {true}
                                    />
                                </div>
                            </div>

                        </div>

                    </div>

                </div>  


                {/* Product Features Spotlight */}
                <div className='bluePageSection'>

                    <div className='innerAligner productPageBody'>

                        <div className='pageSection productPage-LeftRightSection '>

                    
                            <h2>What you’ll get with Observer_01</h2>

                            <div>
                                {product.productFeatures.map((feature, idx) => {

                                    return <FeatureCard 
                                        feature={feature}
                                        key = {idx}
                                    />
                                

                                })}

                            </div>

                            {/* <FeatureCardSet
                                features={product.productFeatures}
                            /> */}

                        </div>

                    </div>

                </div>  


                

                <div className='bluePageSection'>

                    <div className='innerAligner productPageBody'>

                        <div className='pageSection productPage-LeftRightSection '>

                    
                            <h2>Sounds made with Observer_01 </h2>
                    
                            {/* <ReactPlayer 
                                width='100%'
                                url='https://soundcloud.com/ayisen-main/sets/observer_01-sound-examples?si=ca7e495e0385453fad3cde1f38015051'
                                soundcloud = {{color: 'black'}}
                                style = {{width: '50%'}}
                            />*/}

                            <div className='soundCloudWrapper'>
                                <iframe 
                                    width="100%" 
                                    height="300" 
                                    scrolling="no" 
                                    frameBorder="no" 
                                    allow="autoplay" 
                                    src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1323847330&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true">
                                </iframe>
                            </div>

                        </div>

                    </div>

                </div> 


                <div className='innerAligner productPageBody'> 


                    {/* Presets for Purchase */}
                    {/* {addOns && <div className='pageSection'>

                        <h2>... and additional preset packs to help you create</h2>

                        <p className='innerAligner' style={{maxWidth: 600}}>Discover the infinite ways to use Observer_01 with these sample packs, handcrafted by us and other artists bringing their own approaches and sounds. </p>

                        <ProductComponents.PresetCarosel 
                            addOns = {addOns}
                            setCartCount = {props.setCartCount}
                            cart = {props.cart}
                        />

                    </div>} */}


                    {/* System Requirements */}
                    {product.systemRequirements &&<div id='systemRequirements' className='pageSection productPage-LeftRightSection'>

                        <h2>System requirements</h2>
                    
                        <ProductComponents.SystemRequirements 
                            requirements={product.systemRequirements}
                            userManual = {product.userManual}
                            productName = {product.name}
                        />

                    </div>}


                    {/* FAQs */}
                    <div className='pageSection productPage-LeftRightSection'>

                        <h2>FAQ</h2>

                        <div className='faqs'>
                            {product.FAQS && product.FAQS.map((faq, idx) => {

                                return <General.Dropdowns.SimpleDropdown 
                                    key = {idx}
                                    title = {faq.question}
                                >
                                    <div>
                                        {faq.answer}
                                    </div>
                                </General.Dropdowns.SimpleDropdown>

                            })}
                        </div>
                        
                    

                    </div>

                </div>
                

            </>}


            {/* Doesn't Exist */}
            {/* #Tag: Enhancement @Marcel: Handle */}
            {!product && <div className='pageSection'>
                
               No Product Found 
            
            </div>}

        {/* </div> */}

        
        
    

    </div>


}

export default ProductPage;