
import { AllAddOnData, AllProductData, ProdAddOnData, ProductData } from "../types";


export const Observer_01Data : ProductData = {

    name : 'Observer_01',
    uid: 'observer_01',

    purchaseHero: {
        icon: "/resources/Products/Observer_01/Images/EffectsEditor.png",
        description: "Bespoke rhythm generation. Non-linear looping. Atmospheric patterns. Endless possibilities.",
    },

    productFeatures: [
        {
            title: "Complex rhythms",
            description: "Create patterns that utilize the geometric relationships of different shapes and rotations.",
            icon: "/resources/Icons/GeoNew.png",
        }, 
        {
            title: "Dynamic effects",
            description: "Fine tune sounds with a wide variety of effects, including gain, pitch, chance, start, end, fade in, fade out, reverse, retrigger with pitch decay, and spatial panning. Get even more granular by altering these properties for individual collisions between shapes.",
            icon: "/resources/Icons/Trash.png",
        },
        // {
        //     title: "Spatial panning",
        //     description: "Explore complex rhytms with visual ease.",
        //     icon: "/resources/Icons/Move.png",
        // },
        {
            title: "Free presets",
            description: "Experience the full range of Observer_01 with our collection of presets that explore unique approaches to geometric sequencing– designed to get you off the ground in no time.",
            icon: "/resources/Icons/PointEditor.png",
        },
    ],

    banner: '/resources/Products/observerPlaceHolderPhoto4.jpg',
    thumbnail : '/resources/AyisenLogo/IconBlack.png',
    littleIcon : '/resources/AyisenLogo/IconBlack.png',

    // Site Info
    description : 'Dynamic, Geometric, Orbital Sequencer.',

    // Links
    staticPageLink : '/products/observer_01',

    // #Tag: Enhancement: Remove, and use requirements from the latest version.
    systemRequirements: [
        {
            title: "Operating Systems", 
            bullets: [
                "Mac OS X 10.10 or later; Windows 7 or later.",
                "Internet connection required at the time of activation.",
            ],
        },
        {
            title: "Available Formats", 
            bullets: [
                "VST3",
                "AudioUnit",
                // "AAX",
            ],
        }, 
        {
            title: "Supported Hosts",
            bullets: [
                "Logic",
                "Ableton Live",
                // "ProTools (11 or Later)",
                // "Cubase", 
                "Nuendo",
                "Studio One", 
                // "Digital Performer",
            ],
        },
    ],

    FAQS: [
        {
            question: "Activating on multiple devices",
            answer: "Each Ayisen plugin allows you to have 5 instances across different computers."
        },
        {
            question: "How to activate a plugin",
            answer: <div>
                <p>Once a plugin is purchased you will recieve a confirmation link containing your serial number, you can either use this or your Ayisen.com login credentials to activate a plugin.</p>
                <p>
                    You can download your plugins from the "My Prodcuts" page in your account. Once downloaded, unzip and run the installer package. Open your DAW and your plugin will appear in the plugin drop down menu. Once you open the plugin an activation prompt will appear. You can then enter your Ayisen.com login credentials or your serial code to activate. Once you activate you are free to explore!
                </p>
            </div>
        },
        {
            question: "License transfering",
            answer: "Ayisen Licences are non-transferable."
        },
        {
            question: "Can I purchase on a phone/ tablet?",
            answer: "Yes! But you will only be able to use VSTs locally on a machine capable of running a DAW.",
        },
        {
            question: "I forgot my password! how do I change it?",
            answer: <div>
                Visit the <a href="/request-reset-password">reset my password</a> page.
            </div>
        },
        {
            question: "Do I need an account to buy a plugin?",
            answer: "Yes, all downloads, updates and trials are handled via the \"My Prodcuts\" portal."
        },
        {
            question: "Do you sell via third parties?",
            answer: "We do not currently sell via third parties! If you find anyone offering Ayisen products please let us know!",
        },
        {
            question: "I found a bug!",
            answer: "Please send us an email of the bug to ayisenmain@gmail.com",
        },
        {
            question: "Do You have An EULA?",
            answer: <div>
                Sure! You can find it <a href='/license-agreement'>here</a>.
            </div>
        },
        {
            question: "Where Can I read Ayisen's Policy?",
            answer: <div>
                Sure! You can find it <a href="/policy">here</a>.
            </div>
        },
        {
            question: "Do you have an academic discount?",
            answer: <div>
                Yes we do. Please write us at ayisenmain@gmail.com and attach proof of educational status (copy of student ID etc.) and we’ll send you a coupon code.
            </div>
        }
    ],
    

    userManual: "/resources/Products/Observer_01/Documents/Observer_01 Manual.pdf",

};



export const Observer_01AddOnData: {[key: string]: ProdAddOnData} = {

    'observer_01_preset-pack_kendrick_lamar': {
        name: 'Kendrick Lamar Preset Pack',
        uid: 'observer_01_preset-pack_kendrick_lamar',

        thumbnail: "/resources/Products/Observer_01/Packs/Kendrick.jpeg",
        littleIcon: "/resources/Products/Observer_01/Packs/deadmau5.jpeg",
        description: "Sounds from ___",

    },

    'observer_01_preset-pack_deadmau5': {
        name: 'Kendrick Lamar Preset Pack',
        uid: 'observer_01_preset-pack_kendrick_lamar',

        thumbnail: "/resources/Products/Observer_01/Packs/deadmau5.jpeg",
        littleIcon: "/resources/Products/Observer_01/Packs/deadmau5.jpeg",
        description: "Sounds from ___",

    },

    'observer_01_preset-pack_crazy_frog': {
        name: 'Kendrick Lamar Preset Pack',
        uid: 'observer_01_preset-pack_kendrick_lamar',

        thumbnail: "/resources/Products/Observer_01/Packs/crazy-frog.jpeg",
        littleIcon: "/resources/Products/Observer_01/Packs/deadmau5.jpeg",
        description: "Sounds from ___",

    },

    'observer_01_preset-pack_nolan': {
        name: 'Kendrick Lamar Preset Pack',
        uid: 'observer_01_preset-pack_kendrick_lamar',

        thumbnail: "/resources/Products/Observer_01/Packs/christopherNolan.jpeg",
        littleIcon: "/resources/Products/Observer_01/Packs/deadmau5.jpeg",
        description: "Sounds from ___",

    },

    'observer_01_presets_space': {
        name: "Space",
        uid: 'observer_01_presets_space',
        thumbnail: "/resources/Products/Observer_01/Packs/Space.jpeg",
        littleIcon: "/resources/Products/Observer_01/Packs/deadmau5.jpeg",
        description: "Sounds from space, directly to your daw."
    }

}