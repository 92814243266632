
import React, { useState } from 'react';

import FeatureCardSet from '../General/Product/FeatureCardSet';

import '../../styles/Pages/about.css';
import { Feature } from '../../constants/types';
import ArtistTestimonials from '../../constants/artists';
import TeamMembers from '../../constants/team';
import CircleImageGrid, { ImageData } from '../General/Random/CircleImageGrid';
import GeneralComponents from '../General';
import Buttons from '../General/Buttons';
import { NormalButtonStyle } from '../General/Buttons/NormalButton';
import utilities from '../../utilities';
import api from '../../api';
import { toast } from 'react-toastify';
import FeatureCard2 from '../General/Product/FeatureCard2';

const valuePropositions: Feature[] = [

    {
        title: 'Environment as instrument',
        description: 'We are inspired by how your environment, digital or physical, influences your art. Our philosophy is to create highly visual and tangible digital spaces that allow us to interact with sonic phenomena in intuitive ways.',
        icon: "/resources/AyisenLogo/IconBlack.png",
    },
    {
        title: 'Emulate nature, not machine',
        description: "Our take on audio creation is inspired by properties of the natural world (whether that's in the form of rotation-based pattern creation, or fluid-modeling based synthesis).",
        icon: "/resources/AyisenLogo/IconBlack.png",
    },
    {
        title: 'Do, discover, repeat',
        description: "We believe that great tools are not just designed to execute, but to inspire– That’s why with every product, we strive to create moments of discovery and delight, that you can use to expand your sonic landscape.",
        icon: "/resources/AyisenLogo/IconBlack.png",
    },

]

export interface AboutPageProps {
    setLoading: (val: boolean) => any
}

export const About = (props: AboutPageProps) => {


    const [ contactEmail, setContactEmail ] = useState('');
    const [ contactMessage, setContactMessage ] = useState('');

    const artistIcons: ImageData[] = ArtistTestimonials.map(t => {return {icon: (t.icon ?? "/resources/AyisenLogo/IconBlack.png"), label: t.name}});
    const teamIcons: ImageData[] = TeamMembers.map(t => {
        return {
            icon: (t.picture),
            label: (t.name),
        }
    });

    const submitQuestion = async () => {

        const reqBody = {
            email: contactEmail,
            message: contactMessage,
        }

        props.setLoading(true);
        const res = await api.post("marketplace/submit-question", reqBody, {}, true);
        props.setLoading(false);

        if (res && res.success) 
            toast.info("Your question has been submitted!");

    }


    const eamilValid = utilities.verify.isValidEmail(contactEmail);

    return <div className='aboutPage fadeQuick' id="aboutPage">
    

        <div className='aboutPageBanner'>

            <img src = {"resources/Background/AyisenShutter.png"}/>

        </div>

    <div id="aboutPageBody">


        <div className='splitScreenSections'>

            {/* <div className='innerAligner' style={{width: '95%'}}>

                <h1>Let's change the way we see sound.</h1>

                <p>We’re passionate about creating digital music environments that don’t just execute; but inspire. Our tools are designed to inspire you, so you can explore exciting new sonic landscapes in your work.</p>

            </div> */}

            <div className='aboutSection innerAligner' style={{width: '95%'}}>

                <h2>Our design ethos</h2>

                {/* <FeatureCardSet
                    features={valuePropositions}
                /> */}
                <div>
                {valuePropositions.map((prop, idx) => {

                    return <FeatureCard2 
                        feature={prop}
                    />

                })}
                </div>

            </div>

            {/* <div className='innerAligner' style={{width: '95%'}}>

                <h2>Artists using Ayisen</h2>

                <CircleImageGrid
                    images = {artistIcons}
                />

            </div> */}

            <div className='aboutSection innerAligner' style={{width: '95%'}}>

                <h2>Team</h2>

                <CircleImageGrid
                    images = {teamIcons}
                    itemDims = {"45%"}
                />

            </div>


        </div>

        <div className='questionsSection splitScreenSections'>
            <div className='questionsInner innerAligner' style={{width: '95%'}}>

            <h2>Questions?</h2>

            <div className='contactContainer'>

                <GeneralComponents.String.StringInput 
                    placeholder='Your message'
                    value={contactMessage}
                    isMultiLine = {true}
                    editValue = {setContactMessage}
                />

                <GeneralComponents.String.StringInput 
                    placeholder='Your email'
                    value={contactEmail}
                    editValue = {setContactEmail}
                    rightHandText = {"@"}
                />

                <Buttons.NormalButton 
                    text='Submit'
                    onClick={submitQuestion}
                    style={NormalButtonStyle.Dark1}
                    disabled={!eamilValid.status}
                />

            </div>

            </div>

        </div>

    </div>
    </div>


}

export default About;