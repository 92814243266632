
import PurchaseHero from "./PurchaseHero";
import SystemRequirements from "./SystemRequirements";
import FeatureCardSet from "./FeatureCardSet";
import PresetCarosel from "./PresetCarosel";
import ProductInstanceCard from "./ProductInstanceCard";
import PluginInstanceCard from "./PluginInstanceCard";
import AddOnInstanceCard from "./AddOnInstanceCard";

export default {

    PurchaseHero,
    SystemRequirements,
    FeatureCardSet,
    PresetCarosel,
    ProductInstanceCard,
    PluginInstanceCard,
    AddOnInstanceCard,

}