
import React, {WheelEvent, TouchEvent, useCallback} from 'react';

import '../../styles/Pages/home.css';
import { useEffect, useState } from 'react';
import utils from '../../utilities';
import constants from '../../constants';
import useWindowDimensions from '../../utilities/hooks/windowDims';

import _ from "lodash";

export interface ScrollCaptureProps {
    // elemId: string,
    // setScrollPercent: (val: number) => any,
    // topOutPercent?: number,

    children: any,

    // Allow locked state to break the lock and scroll up or down
    allowScrollDownFromLock: () => boolean,
    allowScrollUpFromLock: () => boolean,

    // Continuous Scroll Callback
    continuousScrollCallback?: (deltaY: number) => any,

    // Discrete Scroll Callback
    discreteScrollInfo?: {
        scrollThreshold: number,
        callback: (increment: number) => any,
    },

    onGestureEnd?: (deltaY: number) => any,

}

let lastComponentTop: null|number = 0;
let scrollTracker = 0;

let scrollStartT: null|number = null;
let lastScrollT: null|number = null;
let lastScrollDelta: null|number = null;
let lastVelocity: null|number = null;

let maxVelocity: number = 0;
let ticksSinceMaxV: number = 0;
let maxVTime: number = 0;
let last5Values: number[] = [];

let curScrollAggregate = 0;
let discreteCovered = false;

let lastTouch: {x: number, y: number} | null = null;

/**
 *
 * A component that tracks the scrolling actions made in the window, w.r.t. a given element, and provides updates to the parent compoent via callback. 
 *  
 * @param props 
 * 
 *
 * 
 * @returns 
 */
export const ScrollCapture = (props: ScrollCaptureProps) => {

    // Locked bool
    const [ locked, setLocked ] = useState(false);
    const [ scrollTop, setScrollTop ] = useState(0);
    const [ scrolling, setScrolling ] = useState(false);

    const {width: windowWidth, height: windowHeight} = useWindowDimensions();


    // Enable and disable scrolling based on the lock status 
    useEffect(() => {

        if (locked)
            utils.dom.scrolling.blockScrolling();

        else 
            utils.dom.scrolling.enableScrolling();

    }, [locked]);

    useEffect(() => {
    })

   

    useEffect(() => {



        const onScroll = (e: any) => {

            e.preventDefault();


            // Check for relock needed
            checkForReLock();

            setScrollTop(e.target.documentElement.scrollTop);
            setScrolling(e.target.documentElement.scrollTop > scrollTop);

            
        };
        window.addEventListener("scroll", onScroll);

    
        return () => {
            window.removeEventListener("scroll", onScroll);
            
        }
      }, [locked, props, scrollTop, scrolling]);


    // Mount
    useEffect(() => {

        lastTouch = null;
        scrollTracker = 0;

        // Set the current component top
        // Block scrolling 
        const scrollCapture = document.getElementById('scrollCapture');
        const componentRect = scrollCapture?.getBoundingClientRect();
        if (!componentRect)
            return;

        const componentTop = componentRect.top - constants.general.getHeaderHeight(windowWidth);

        lastComponentTop = componentTop;

        return () => {

            utils.dom.scrolling.enableScrolling();

        }

    }, [] )




    const lockToLocOnCurrentScreen = (locY: number) => {

        // Jump to component
        window.scrollTo({
            top: locY + window.pageYOffset,
            behavior: 'auto',
        })

        // Lock
        if (!locked)
            setLocked((true));

    }


    /**
     * 
     * These are the conditions under which the the block SHOULD be in view. 
     * 
     * We'll check for these everytime there is a scrolling action of any type. 
     * 
     * If 
     * 
     * 
     */
    const checkForReLock = () => {

        // Block scrolling 
        const scrollCapture = document.getElementById('scrollCapture');
        const componentRect = scrollCapture?.getBoundingClientRect();

        if (!componentRect)
            return;

        const componentTop = componentRect.top - constants.general.getHeaderHeight(windowWidth);

        // console.log("Comp Top: ", componentTop);
        // console.log("Can Down: ", props.allowScrollDownFromLock(), " :: Up: ", props.allowScrollUpFromLock())


        // If we're above and shouldn't be allowed to be...  (1 to allow for things close to 0, but still blocking +)
        if (!props.allowScrollUpFromLock() && (componentTop > 0.5)) {

            // Lock to there
            lockToLocOnCurrentScreen(componentTop);

        }

        // If we're below and shouldn't be allowed to be... 
        if (!props.allowScrollDownFromLock() && (componentTop < -0.5)) {

            // Lock to there
            lockToLocOnCurrentScreen(componentTop);

        }

        // ... otherwise, we're rightfully locked!
        // else if (locked && (Math.abs(componentTop) > 1)) {
        //     console.log("Removing Lock. CompTop: ", componentTop)
        //     // Unlock
        //     setLocked(false);

        // }

    }

    // To be called at the end of the gesture

    /**
     * NOTE :: This queue function needs to 
     *  - Remain the same, so that the debounce can track and aggregate calls and not duplicate. 
     *  - Change whenever the active index changes. 
     * 
     *  .... therefore, we need to allow it to change,... and have something else prevent it from doing anything else after. 
     * 
     *  .... The issue is, if the function changes mid way through the gesture (because one of the callback deps changes, like slide idx)
     *  .... This would lead the OLD VERSION OF THE DEBOUNCE TO FIRE. 
     *  
     *  This will cause the "Already Handled Value" to reset. 
     * 
     *  ... which we can't allow. 
     * 
     *  SO... we need some callback that DOES NOT DEPEND ON ANY OF THESE PARAMETERS, that can restart once the callback is over. 
     *  
     *  That way this gesture end, and that can go off, and there shouldn't be any problems. 
     * 
     */
    // const queueGestrueEndCallback = useCallback(_.debounce(() => gestureEndCallback(), 100), [props.discreteScrollInfo?.callback]);
    // const gestureEndCallback = () => {

    //     console.log("GESTURE ENDED");

    //     // The gesture is now over. 

    //     // Execute on it if we need to. 

    //     // console.log("Debounce Callback!");

    //     // Get the amount we scrolled total. 
    //     if (!props.discreteScrollInfo)
    //         return;

    //     // Over scroll threshold?
    //     if (Math.abs(curScrollAggregate) > props.discreteScrollInfo.scrollThreshold) {

    //         // Not already hadled? 
    //         if (!discreteCovered) {

    //             console.log("GestureEnd: ", curScrollAggregate, " / ", props.discreteScrollInfo.scrollThreshold);

    //             // Perform the callback!
    //             const offset = (curScrollAggregate > 0 ? 1 : -1 );
    //             props.discreteScrollInfo.callback( offset );

    //         }
            
    //     }

        

    // }


    const getShouldUnlockAtEndOfGesture = () => {

        // ------- Are we breaking out of a lock? -------

        // Allow scroll up and break lock?
        if (props.allowScrollUpFromLock() && curScrollAggregate < 0) {

            return true;

        }

        // Allow scroll down and break lock? 
        if (props.allowScrollDownFromLock() && curScrollAggregate > 0) {
            
            // lastComponentTop = null;  // Set the top to negative, so the lock doesn't catch it next scroll
            return true;

        }

        return false;


    }


    const queueGestureEnd =  useCallback(_.debounce((shouldUnlock: boolean,  aggregateDeltaY: number) => onGestureEnd(shouldUnlock, aggregateDeltaY), 150), [props.onGestureEnd]);
    const queueGestureEndShort =  useCallback(_.debounce((shouldUnlock: boolean,  aggregateDeltaY: number) => onGestureEnd(shouldUnlock, aggregateDeltaY), 50), [props.onGestureEnd]);
    const onGestureEnd = (shouldUnlock: boolean, aggregateDeltaY: number) => {

        // console.log("Ended")
        if (shouldUnlock) {

            setLocked(false);
            lastComponentTop = null;

        }

        // Call callback
        if (props.onGestureEnd)
            props.onGestureEnd(aggregateDeltaY);

    }


    /**
     * Ugh... and if THIS is going to handle unlocking at the end of the gesture.... 
     * 
     * Then it's going to need to have these allowScroll___FromLock things as dependances.... 
     * Which will be changing in the middle of an animation, which will reset this function, and cause it to trigger twice, since there are two debounces. 
     * 
     * Maybe I can pass a callback to the debounce? A function that is "Should Unlock?"
     * 
     * This function would need to be able to know if the caller of the debounce was asking it to reset... 
     * 
     * Bleh.... 
     */
    const queueGestrueReset =  useCallback(_.debounce(() => gestureReset(), 100), []);
    const queueGestrueResetShort =  useCallback(_.debounce(() => gestureReset(), 50), []);
    const gestureReset = () => {

        // console.log("In reset with agg: ", curScrollAggregate)


        // Clear all gestrue specific data
        curScrollAggregate = 0;
        discreteCovered = false;
        scrollStartT = null;
        maxVelocity = 0;

        lastScrollT = null;
        maxVTime = 0;
        ticksSinceMaxV = 0;

    }



    const queueThresholdCrossedInGesture = useCallback(_.debounce(() => thresholdCrossedInGesture(), 20), [props.discreteScrollInfo]);
    const thresholdCrossedInGesture = () => {

        if (!props.discreteScrollInfo)
            return;

        // console.log("Over Thresh Callback: ", curScrollAggregate, " / ", props.discreteScrollInfo.scrollThreshold);

        // Note that we covered it
        discreteCovered = true;

        // Perform the callback!
        const offset = (curScrollAggregate > 0 ? 1 : -1 );
        props.discreteScrollInfo.callback( offset );

    }

    const checkCurForDiscreteTrigger = useCallback(() => {

        // We're in the middle of a gesture, but we're checking if we went over the threshold just now. 
        // If we did... we're going to post to a thread that will take care of it quickly.. but prevent simultaneous posting. 

        // console.log("Dis Cov: ", discreteCovered)

        if (!props.discreteScrollInfo)
            return;

        // Already covered?
        if (discreteCovered)
            return;

        

        // Over scroll threshold?
        if (Math.abs(curScrollAggregate) > props.discreteScrollInfo.scrollThreshold) {
            // Queue crossover callback!
            // console.log("Queueing Thresh Cross")
            discreteCovered = true;
            queueThresholdCrossedInGesture();
        }

        

    }, [props.discreteScrollInfo]);



    const onNewScrollStart = () => {

        // We're starting a new scroll! Reset everything!
        // onGestureEnd(getShouldUnlockAtEndOfGesture(), curScrollAggregate);
        gestureReset();

        // TODO @Marcel: Allow new scroll to break out of lock
        // TODO @Marcel: Maybe allow new scroll up to progress from the Rot->Spotlight. (right now has to wait for end, like the lock break).
        // TODO @Marcel: Clean this up A LOT lol. 
        // TODO @Marcel: Remove all console logs (and unnecessary vars).

        /*
            The concept of detecting the start of new gestrues is potentially big. 

            It's basically the same type of action as detecting the END of gestures for touch events. 

        */


    }
    
    
    const trackMaxVelocity = (deltaY: number) => {


        // Note the time if needed
        const curT = (new Date()).getTime(); 
        if (lastScrollT !== null) {

            const scrollVelocity = Math.abs(deltaY / (curT - lastScrollT));
            // console.log("V: ", scrollVelocity, "Ts: ", ticksSinceMaxV)

            // If the time since the maximum is more than half a second...
            if (maxVTime && ((curT - maxVTime) / 1000) > 0.5) {
            // if (true) {
                

                // ... and we've hit a new LOCAL maximum... (measured based on a rolling avg?)
                const localAvg = (last5Values.reduce(function(pv, cv) { return pv + cv; }, 0) / last5Values.length);
                if (scrollVelocity > (localAvg)) {

                    // console.log("New Scroll Maxing!", scrollVelocity , " :: ", last5Values, " :: ", localAvg);
                    // console.log("ScrollStart")
                    maxVelocity = scrollVelocity;
                    maxVTime = curT;
                    ticksSinceMaxV = 0;

                    return onNewScrollStart();

                }

            }

            

            // Is this the max? 
            if (scrollVelocity > maxVelocity && Number.isFinite(scrollVelocity)) {

                // if (maxVTime && ((curT - maxVTime) / 1000) > 0.5) 
                //     console.log("New T Scroll!");

                // if (ticksSinceMaxV > 10)
                //     console.log("New Scroll!!!");

                maxVelocity = scrollVelocity;
                maxVTime = curT;
                ticksSinceMaxV = 0;

            }

            else {
                ticksSinceMaxV += 1;
            }


            last5Values.push(scrollVelocity);
            if (last5Values.length > 10)
                last5Values.shift();

        }

        lastScrollT = (new Date()).getTime();
        


    }


    const onAttemptedScroll = (deltaY: number, hasInertia = true) => {

        // Track velocity
        if (hasInertia)
            trackMaxVelocity(deltaY);

        // Check for relock needed
        checkForReLock();


        if (locked) {


            


            // ------- Not breaking... catalog internal scrolls! -------


            // No change?
            if (deltaY === 0)
                return;

            // Continuous Scroll
            if (props.continuousScrollCallback !== undefined) {
                props.continuousScrollCallback(deltaY);

                // Allow scroll up and break lock?
                // TODO @Marcel: This is only okay to do here, b/c the top compoennt i'm currently using is NON DISCRETE!
                if (props.allowScrollUpFromLock() && curScrollAggregate < 0) {

                    setLocked(false);
                    return;

                }
            }


            // Discrete Scroll 
            if (props.discreteScrollInfo) {

                // Check if we've triggered a discrete event
                checkCurForDiscreteTrigger();

                // queueGestrueEndCallback();
                if (!hasInertia) {
                    // queueGestrueResetShort();
                }
                else
                    queueGestrueReset();

                

            }

            // Update cur scroll aggregate 
            curScrollAggregate += deltaY;

            if (!hasInertia) {
                // queueGestureEndShort(getShouldUnlockAtEndOfGesture(), curScrollAggregate);
            }
            else
                queueGestureEnd(getShouldUnlockAtEndOfGesture(), curScrollAggregate);


        }

        


    }


    const onComponentWheel = (e: WheelEvent<HTMLDivElement>) => {
        
        onAttemptedScroll(e.deltaY);

    }

    const onComponentTouchMove = (e: TouchEvent<HTMLDivElement>) => {

        if (e.touches.length <= 0)
            return;

        const curLoc = {x: e.touches[0].screenX, y: e.touches[0].screenY}

        if (lastTouch) {

            const touchDist = -(curLoc.y - lastTouch.y);
            onAttemptedScroll(touchDist, false);

        }

        lastTouch = curLoc;


        // onAttemptedScroll(e.);
    }

    const onTouchEnd = () => {
        lastTouch = null;
        onGestureEnd(getShouldUnlockAtEndOfGesture(), curScrollAggregate);
        gestureReset();

    }


    const onComponentScroll = (e: any) => {
        // console.log("Scroll!!!")

        // onAttemptedScroll(e.deltaY);

    }



    return <div id='scrollCapture'
        style = {{touchAction: (locked ?  'none': undefined)}}
        onWheel={onComponentWheel}  onScroll={onComponentScroll} onTouchEnd={onTouchEnd} onTouchMove={onComponentTouchMove}>

        {props.children}

    </div>


}

export default ScrollCapture;