

import React from 'react';
import { AllAddOnData, AllProductData, AllUserProducts } from '../../../constants/types';
import ProductComponents from '../Product';

import '../../../styles/General/Account/productInstanceViewer.css';
import utils from '../../../utilities';


export interface ProductInsanceViewerProps {

    userProducts: AllUserProducts,
    products: AllProductData,
    addOns: AllAddOnData,
    setLoading: (val: boolean) => any,

};


export const ProductInstanceViewer = (props: ProductInsanceViewerProps) => {


    // ALL User Products (flat)
    const userProducts = Object.values(props.userProducts).flat();
    
    // Get all addons (flat)
    const allAddOns = utils.product.flattenAddOns(props.addOns);

    return <div className='fadeQuick'>

        {/* No Products? */}
        {userProducts.length <= 0 && <>

            <div className='noUserProductsDiv'>
                <h3>No products yet to show</h3>
            </div>

        </>}

        {userProducts.map((userProduct, idx) => {

            // Plugin
            if (userProduct.productUID in props.products) {

                return <ProductComponents.PluginInstanceCard 
                    key = {idx}
                    productInstance = {userProduct}
                    product = {props.products[userProduct.productUID]}
                    setLoading = {props.setLoading}
                />

            }

            // Add On
            else if (userProduct.productUID in allAddOns) {
                
                return <ProductComponents.AddOnInstanceCard 
                    key = {idx}
                    products = {props.products}
                    productInstance = {userProduct}
                    product = {allAddOns[userProduct.productUID]}
                    setLoading = {props.setLoading}
                />

            }
            
            return null;

        })}
        
    </div>

}

export default ProductInstanceViewer;