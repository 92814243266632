
import React, { useEffect, useState } from 'react';
import { ModalState, ModalStateType } from '../../constants/types';

import '../../styles/Modals/modalWrapper.css';
import DeleteAccountModalBody from './DeleteAccountModalBody';
import LoginModalBody from './LoginModalBody';
import ChangeAvatarModalBody from './ChangeAvater';



export interface ModalWrapperProps {
    state: ModalState,
    setModalState: (state: ModalState|null) => any,
    deleteAccount: (password: string) => any,
    login: (email: string, password: string) => any,
    fetchUserData: () => any,
}

const ModalWrapper = (props: ModalWrapperProps) => {


    return <div className='modalWrapper'>

        {/* Backdrop */}
        <div className='modalBackdrop'/>

        <div className='modalBody fadeQuicker'>

            <img 
                className='closeIcon' 
                src="/resources/Icons/close.png"
                alt="X"
                onClick={() => props.setModalState(null)}
            />

            {/* Body */}
            {props.state?.state === ModalStateType.DeleteAccount && <>
                <DeleteAccountModalBody 
                    setModalState = {props.setModalState}
                    deleteAccount = {props.deleteAccount}
                />
            </>}

            {props.state?.state === ModalStateType.Login && <>
                <LoginModalBody 
                    login = {props.login}
                    setModalState = {props.setModalState}
                    message = {props.state.message}
                />
            </>}

            {props.state?.state === ModalStateType.ChangeAvatar && <>
                <ChangeAvatarModalBody 
                    setModalState = {props.setModalState}
                    fetchUserData = {props.fetchUserData}
                />
            </>}

            
        </div>


    </div>


}

export default ModalWrapper;