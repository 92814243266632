
import roundToCents from "./roundToCents";

const getDisplayPrice = (price: number|undefined, salePrice: number|undefined) => {

    let onSale = (salePrice || (salePrice === 0));

    // Price 
    let displayPrice = price ? roundToCents(price) : undefined;
    let displaySalePrice = (salePrice || (salePrice === 0)) ? roundToCents(salePrice) : undefined;

    // No price => No sale!
    if (displayPrice === undefined) {
        displaySalePrice = undefined;
        onSale = false;
    }

    let forSale = true;
    if (!displayPrice && (displayPrice !== 0))
        forSale = false;

    return {
        onSale, 
        displayPrice,
        displaySalePrice,
        forSale,
    }

}

export default getDisplayPrice;