

import { AllProductData, ProdAddOnData } from "../../constants/types";

/*
    Flatten out addons, to not be nested by product

*/
export const getParentProduct = (product: ProdAddOnData, allProducts: AllProductData) => {

    // Get the name of the parent product
    if (product.details?.parentProduct) {

        let parentProduct = Object.values(allProducts).find(prod => (prod.details?._id === product.details?.parentProduct));

        if (parentProduct)
            return parentProduct;

    }

    return null;

}

export default getParentProduct;