
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ReactLoading from 'react-loading';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import api from './api';
import { stripe } from './utilities/stripe';

import './App.css';
import './styles/root.css';
import './styles/ayisen.css';

import utilities from './utilities';
import { AllAddOnData, AllProductData, AllUserProducts, Cart, ModalState, User } from './constants/types';

import Header from './components/Headers/Header';
import Home from './components/Pages/Home/Home';
import CartPage from './components/Pages/Cart';
import Footer from './components/Headers/Footer';
import PurchaseComplete from './components/Pages/PurchaseComplete';
import Pages from './components/Pages';
import ModalWrapper from './components/Modals/ModalWrapper';

import { addOnUIData, injectAPIProductData, productUIData } from './constants/product';
import useEffectOnce from './utilities/hooks/useEffectOnce';
import AnalyticsWrapper from './components/General/GoogleAnalytics/AnalyticsWrapper';


const NO_HEADER_ROUTES: string[] = [
    // '/recover-password', 
    // '/confirmEmail/*',
    // '/login',
    // '/login/*'
  ];

const NO_FOOTER_ROUTES: string[] = [
    // '/recover-password', 
    // '/reset-password/*', 
    // '/confirmEmail/*',
    // '/create-account',
    // '/login',
    // '/login/*'
];



export const App = () => {

    // Loading
    const [loading, setLoading] = useState(false);

    // User
    const [user, setUser] = useState<User|null>(null);
    const [ authTried, setAuthTried ] = useState(false);
    const [userProducts, setUserProducts] = useState<AllUserProducts>({});

    // Cart
    const [cart, setCart] = useState<Cart>([]);

    // Products
    const [products, setProducts] = useState<AllProductData>(productUIData);

    // Add Ons
    // TODO @Marcel: Integrate! 
    const [addOns, setAddOns] = useState<AllAddOnData>(addOnUIData);

    // Modal State
    const [modalState, setModalState] = useState<ModalState|null>(null);


    // On Mount
    useEffectOnce(() => {

        // Init 
        initApp();


    });

    // Cart Update
    useEffect(() => {

        // Save cart to local!
        if (cart && cart.length !== 0)
            utilities.cart.saveToLocal(cart);

    }, [cart]);



    const initApp = async () => {

        setLoading(true);

        await fetchUserData();

        // Load cart from local
        loadCartFromLocal();

        // Load product data
        await loadProductData();

        setLoading(false);

        // #Tag: Enhancement: Fetch FAQs, and insert them into the products!

        // #Tag: BugNote: Init stripe? 

    }

    const fetchUserData = async () => {

        // Fetch current user
        const res = await api.get('user/whoami', {}, false, true);
        if (res && res.user && res.user._id) {
            setUser(res.user);
        }

        setAuthTried(true);

    }

    const loadProductData = async () => {

        // Fetch the product information from API
        const res = await api.get('marketplace/get-all');

        if (res && res.products) {

            // Hybrid the product data with the default data!
            // TODO @Marcel: Handle add-ons
            const { hybridProducts, hybridAddOns } = injectAPIProductData(res.products);

            setProducts(hybridProducts);
            setAddOns(hybridAddOns);

        }

    }

    const fetchUserProducts = async () => {

        // No user? 
        if (!user || !user._id)
            return;

        setLoading(true);

        const res = await api.get('user/products/get');
        if (res && res.products) {

            // Extract out the user instances 
            const filledProducts = Object.fromEntries(res.products.map((prodInfo: any) => {

                return [
                    prodInfo.product.uid,

                    // Link product instances to the productUid
                    prodInfo.userInstances.map((instance: any) => {
                        return {
                            ...instance,
                            productUID: prodInfo.product.uid,
                        }
                    }),
                ]

            }))

            setUserProducts(filledProducts);

        }

        setLoading(false);

    }


    const loadCartFromLocal = async () => {

        const localCart = utilities.cart.loadFromLocal();
        setCart(localCart);

        // setCart([{uid: 'observer_01', count: 2}]);
    
    }

    // Clear cart
    const clearCart = () => {
        utilities.cart.saveToLocal([]);
        setCart([]);
    }

    const addToCart = (productUid: string, startingCount = 1) => {

        let curCart = cart;

        // Smart incriment the count of the cart itmes
        const existingIdx = curCart.findIndex(prod => prod.uid === productUid);

        // Increment
        if (existingIdx >= 0) {

            // Increment Val and set!
            curCart[existingIdx].count += 1;
            setCart(curCart);

            return;
        }

        // New
        else {
            const newItem = {uid: productUid, count: startingCount};
            setCart([...cart, newItem]);
        }

    }

    const removeFromCart = (productUid: string) => {

        let newCart = cart.filter(curItem => {
            return (curItem.uid !== productUid);
        });
    
        setCart(newCart);

        if (newCart.length === 0)
            clearCart();
            
    }

    const setCartCount = async (productUid: string, count: number) => {


        let curCart = cart;
    
        // Smart incriment the count of the cart itmes
        const existingIdx = curCart.findIndex(prod => prod.uid === productUid);
    
        // Existing
        if (existingIdx >= 0) {

    
            // Increment Val and set!
            curCart[existingIdx].count = count;

            // Threshold
            if (curCart[existingIdx].count < 0)
                curCart[existingIdx].count = 0;

            // Remove from cart? 
            if (curCart[existingIdx].count === 0) {
                removeFromCart(productUid);
            }

            else {
                setCart([...curCart]);
            }
        
            return;
    
        }

        // Add?
        else if (count > 0) {
            addToCart(productUid, count);
        }
    
    }


    const checkoutCart = async () => {

        
        await setLoading(true);


        // Send cart to api
        let res = await api.post('marketplace/checkout', {cart: cart}, {}, true)

        if (!res.checkoutSessionId && !res.uiMessageSent) {
            let message = 'Unable to complete checkout. Please try again later!';
            toast.error(message);
        }

        if (res.checkoutSessionId) {
            const StripeClient = await stripe();
            if (!StripeClient) {
                toast.error('Unable to connect to merchant. Please try again later, or contact our support team.');
            }
            else {
                StripeClient.redirectToCheckout({ sessionId: res.checkoutSessionId });
            }
        }

        setLoading(false);
        
    }

    const deleteAccount = async (password: string) => {
        /*
            NOTE: This is the final action, will actually delete the account!
        */

        setLoading(true);

        // API Delete Call
        const res = await api.post('user/delete', {password}, {}, true);

        if (res && res.success) {

            // Redirect
            window.location.href= '/account-deleted';
            setModalState(null);    

        }

        setLoading(false);
        
    }

    const login = async (email: string, password: string) => {

        // Login User
        const res = await api.post('user/login', { email, password }, {}, true);

        if (res && res.success) {

            setUser(res.user);

            setModalState(null);
        }

    }



    return (
        <div className={"App" + ((modalState!==null) ? " App-Modal-Open" : "")}>

            {/* <div
                className='screenLock'
            /> */}

            {/* Loading Icon */}
            {loading && <>
                <div className='loadingContainer'>
                    <div className='loading'>
                        <ReactLoading type={'cylon'} height={'100%'} width={'100%'} />
                    </div>
                </div>
            </>}            

            <Router>
                <AnalyticsWrapper>

                    {/* Modal */}
                    {(modalState !== null) && <>
                        <ModalWrapper 
                            state = {modalState}
                            setModalState = {setModalState}
                            deleteAccount = {deleteAccount}
                            login = {login}
                            fetchUserData = {fetchUserData}
                        />
                    </>}

                    {/* Header */}
                    <Routes>
                        
                        {/* Block Header */}
                        {NO_HEADER_ROUTES.map((path, idx) => {
                            return <Route 
                                path={path}
                                key={idx}
                            />
                        })}

                        {/* Header */}
                        <Route path = "*" element = {<Header 
                                cart={cart}
                                user={user}
                                setModalState = {setModalState}
                            />} 
                        />

                    </Routes>

                    {/* Main Page */}
                    <Routes>



                        {/* ------- STATIC ------- */}
                        
                        {/* Home */}
                        <Route path = "/" element = {<Home
                        
                        />}/>

                        {/* About */}
                        <Route path = "/about" element = {<Pages.About
                            setLoading={setLoading}
                        />}/>


                        {/* ------- USER ------- */}

                        {/* My Account */}
                        <Route path = "/my-account" element = {<Pages.MyAccount
                            user = {user}
                            setModalState = {setModalState}
                            products = {products}
                            addOns = {addOns}
                            userProducts = {userProducts}
                            authTried = {authTried}
                            fetchUserProducts = {fetchUserProducts}
                            setUser = {setUser}
                            setLoading = {setLoading}
                        />}/>

                        
                        {/* ------- PRODUCTS ------- */}

                        {/* Product */}
                        <Route path = "/product">
                            <Route path = ":product_uid" element = {<Pages.ProductPage 
                                products = {products}
                                addOns = {addOns}
                                cart = {cart}
                                setCartCount = {setCartCount}
                                setModalState = {setModalState}
                                setLoading = {setLoading}
                                user = {user}
                            />}/>

                        </Route>


                        {/* ------- CHECKOUT FLOW ------- */}

                        {/* Cart */}
                        <Route path = "/cart" element = {<CartPage
                            products = {products}
                            addOns = {addOns}
                            cart = {cart}
                            user = {user}
                            modalState = {modalState}
                            authTried = {authTried}
                            setCartCount = {setCartCount}
                            checkoutCart = {checkoutCart}
                            setModalState = {setModalState}
                        />}/>

                        {/* Purchase Complete */}
                        <Route path = "/purchase-complete" element = {<PurchaseComplete
                            clearCart = {clearCart}
                        />}/>



                        {/* ------- ACCOUNT OPERATIONS ------- */}

                        {/* Delete */}
                        <Route path = "/account-deleted" element = {<Pages.TextAndButtonPage
                            title='Your account has been deleted'
                            buttonText='Return to home'
                            buttonClick={() => {
                                window.location.href = "/";
                            }}
                        />}/>

                        {/* Email Pending */}
                        <Route path = "/email-pending" element = {<Pages.TextAndButtonPage
                            title='A confirmation link has been sent to your email.'
                            buttonText='Return to home'
                            buttonClick={() => {
                                window.location.href = "/";
                            }}
                        />}/>

                        {/* Create */}
                        <Route path = "/create-account" element = {<Pages.LoginOps.CreateAccount
                            setLoading = {setLoading}
                        />}/>

                        {/* Request Pass Reset */}
                        <Route path = "/request-reset-password" element = {<Pages.LoginOps.RequestPassReset
                            setLoading = {setLoading}
                        />}/>

                        {/* Pass Reset */}
                        <Route path = "/reset-password/:access_code" element = {<Pages.LoginOps.ResetPassword
                            setUser = {setUser}
                            setLoading = {setLoading}
                        />}/>

                        {/* Confirm Account */}
                        <Route path = "/confirm-email/:email/:code" element = {<Pages.LoginOps.ConfirmEmail 
                            setModalState = {setModalState}
                        />}/>

                        {/* ------- LEGAL ------- */}

                        {/* License Agreement */}
                        <Route path = "/license-agreement" element = {<Pages.Legal.LicenseAcreementPage
                        />}/>

                        {/* Policy */}
                        <Route path = "/policy" element = {<Pages.Legal.PolicyPage
                        />}/>

                        {/* Terms and Conditions */}
                        <Route path = "/terms-and-conditions" element = {<Pages.Legal.TermsAndConditions />} />

                        {/* Cookie Policy */}
                        <Route path = "/cookie-policy" element = {<Pages.Legal.CookiePolicyPage />} />

                        {/* Refunds Policy */}
                        <Route path = "/refunds" element = {<Pages.Legal.Refunds />} />


                    </Routes>

                    {/* Footer */}
                    <Routes>
                            
                            {/* Block Header */}
                            {NO_FOOTER_ROUTES.map((path, idx) => {
                                return <Route 
                                    path={path}
                                    key={idx}
                                />
                            })}

                            {/* Header */}
                            <Route path = "*" element = {<Footer 
                                    user = {user}
                                />} 
                            />

                    </Routes>


                </AnalyticsWrapper>
            </Router>

            <ToastContainer />

        </div>
    )

}



export default App;