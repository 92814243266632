import CreateAccount from "./CreateAccount";
import ConfirmEmail from "./ConfirmEmail";
import RequestPassReset from "./RequestPassReset";
import ResetPassword from "./ResetPassword";

export default {

    CreateAccount,
    ConfirmEmail,
    RequestPassReset,
    ResetPassword,

}