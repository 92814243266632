import { ProductData } from "../../constants/types";


export default (apiProducts: any[], localProduct: ProductData) => {


    // Get API data
    const apiData = apiProducts.find(elem => (elem.uid === localProduct.uid));
    localProduct.details = apiData;

    return localProduct;

}
