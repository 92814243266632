
import React, { useEffect, useState } from 'react';

import '../../../styles/General/Product/featureCard.css'
import { Feature, SystemRequirements } from '../../../constants/types';



export interface FeatureCardProps {

    feature: Feature,

}

const FeatureCard = (props: FeatureCardProps) => {


    return <div className='featureCard'>

        {/* Icon? */}
        {props.feature.icon && <img
            src={props.feature.icon}
        />}
        <h2>{props.feature.title}</h2>
        <p>{props.feature.description}</p>

    </div>


}

export default FeatureCard;