

export default (password: string) => {

    // Must be at least 8 characters long
    const minChars = 8;
    if (password.length < minChars)
        return {
            status: false, 
            message: "Password must be at least " + minChars + " characters long."
        };

    // Must include at least 1 digit
    const numberRegex = /\d/;
    if (!numberRegex.test(password)) {
        return {
            status: false,
            message: "Password must include at least 1 digit."
        };
    }

    // At least 1 special character
    const specialCharsRegex = /(?=.*\W)/;
    if (!specialCharsRegex.test(password)) {
        return {
            status: false,
            message: "Password must include at least 1 special character.",
        };
    }

    return {
        status: true,
    }

}