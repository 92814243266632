
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {toast} from 'react-toastify';

import "../../../styles/Pages/loginOps.css"
import utilities from '../../../utilities';
import api from '../../../api';
import NormalButton, { NormalButtonStyle } from '../../General/Buttons/NormalButton';
import StringInput from '../../General/String/StringInput';
import { User } from '../../../constants/types';
import GenericPageWrapper from '../GenericPageWrapper';



export interface PassResetProps {
    setUser: (user: User|null) => any
    setLoading: (loading: boolean) => any

}

export const ResetPassword = (props: PassResetProps) => {

    const navigate = useNavigate();
    const params = useParams();

    const [ email, setEmail ] = useState('');
    const [ password, setPassword ] = useState('');
    const [ passwordConfirm, setPassConfirm ] = useState('');
    const [ resetSuccess, setResetSuccess ] = useState(false);

    // Create new user
    const submit = async () => {

        const emailValid = utilities.verify.isValidEmail(email);
        if (!emailValid.status) {
            toast.error(emailValid.error);
            return;
        }

        const passValid = utilities.verify.isValidPassword(password);
        if (!passValid.status) {
            toast.error(passValid.message);
            return;
        }

        const accessCode = params['access_code'];
        if (!accessCode) {
            toast.error("Invalid access-code provided.");
            return;
        }

        props.setLoading(true);


        // Send api request!
        const res = await api.post('user/password/reset', {
            email: email,
            accessCode: accessCode,
            password
        }, {}, true);

        props.setLoading(false);
        
        // Success? 
        if (res && res.success) {
            
            setResetSuccess(true);

            if (res.user) {
                props.setUser(res.user);
            }

        }

    }


    const emailValid = utilities.verify.isValidEmail(email);
    const passValid = utilities.verify.isValidPassword(password);
    const passMatch = (password === passwordConfirm);

    const canSubmit = (emailValid && passValid && passMatch);


    return <GenericPageWrapper><div className='passResetPage' id="passResetPage">


        <div className='passResetBody innerAligner'>

            {resetSuccess ? <>

                <h2>Your password was reset!</h2>

                <div className='inputSet'>

                    <NormalButton
                        text='Continue shopping'
                        onClick={() => navigate('/product/observer_01')}
                        style = {NormalButtonStyle.LightWithBorder1}
                        fillWidth = {true}
                    />

                </div>
            
            </> : <>
            
                <h2>Change password</h2>


                <div className='inputSet'>

                    <StringInput 
                        value = {email}
                        editValue = {setEmail}
                        placeholder = "Your email"
                    />

                    <StringInput 
                        value = {password}
                        editValue = {setPassword}
                        placeholder = "New password"
                        isSecret = {true}
                    />

                    <StringInput 
                        value = {passwordConfirm}
                        editValue = {setPassConfirm}
                        placeholder = "Confirm password"
                        isSecret = {true}
                    />

                    <NormalButton
                        text='Change password'
                        onClick={submit}
                        style = {NormalButtonStyle.Dark1}
                        fillWidth = {true}
                        disabled = {!canSubmit}
                    />

                </div>
            
            
            </>}

        </div>

    </div></GenericPageWrapper>


}

export default ResetPassword;