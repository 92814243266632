
import React from 'react';


import '../../../styles/General/Product/featureCard.css'
import FeatureCard from './FeatureCard';
import { Feature } from '../../../constants/types';



export interface FeatureCardSetProps {

    features: Feature[],

}

const FeatureCardSet = (props: FeatureCardSetProps) => {
    

    return <div className='featureCardSet'>

        {props.features.map(feature => {
            return <FeatureCard 
                feature = {feature}
            />
        })}



    </div>


}

export default FeatureCardSet;