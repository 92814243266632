
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import CartTable, { CartTableRowProps } from '../General/Cart/CartTable';

import '../../styles/Pages/cart.css'
import NormalButton, { NormalButtonStyle } from '../General/Buttons/NormalButton';
import { AllProductData, AllAddOnData, Cart, ModalState, ModalStateType, User } from '../../constants/types';


export interface CartPageProps {

    cart: Cart,
    products: AllProductData,
    addOns: AllAddOnData,
    user: User|null,
    modalState: ModalState|null,
    authTried: boolean,

    setCartCount: (productUid: string, count: number) => any,
    checkoutCart: Function,
    setModalState: (state: ModalState|null) => any,

}


const CartPage = (props: CartPageProps) => {

    const navigate = useNavigate();

    let userCheckTimer: any = null;

    // On Mount 
    useEffect(() => {

    }, []);

    useEffect(() => {

        checkForAuth();

    }, [props.authTried]);


    const checkForAuth = async () => {

        if (!props.authTried) 
            return;

        // Clear any previous timeout
        clearTimeout(userCheckTimer);

        const callback = (() => {

            // Not Logged in? 
            if (!props.user && props.cart.length > 0) {

                props.setModalState({
                    state: ModalStateType.Login,
                    message: "To use any version of Observer _01 (including our free trial) you need an Ayisen account.",
                })

            }

            // If someone is signed in... and we DO have that modal present.... 
            // Get rid of it!
            else {
                if (props.modalState?.state === ModalStateType.Login) {
                    props.setModalState(null);
                }
            }
        });

        // Wait a sec for login to propagate
        userCheckTimer = setTimeout(callback, 500);

    }


    const initLogin = async () => {
        props.setModalState({state: ModalStateType.Login});
    }

    // Construct Cart Table Props
    const cartTableItems: CartTableRowProps[] = props.cart.map(item => {

        let prod = undefined; 
        if ((item.uid in props.products && props.products[item.uid].price))
            prod = props.products[item.uid];

        else {

            for (let prodId of Object.keys(props.addOns)) {

                if ((item.uid in props.addOns[prodId] && props.addOns[prodId][item.uid].price))
                    prod = props.addOns[prodId][item.uid];
            }
        }

        if (!prod)
            return {
                uid: 'unknown',
                name: 'unknown',
                quantity: item.count,
                price: -1,
                salePrice: -1,
            };

        return {
            uid: item.uid,
            name: prod.name,
            quantity: item.count,
            price: prod.price ?? -1,
            salePrice: prod.salePrice,
        }

    }).filter(e => e.price >= 0);

    return <div className='cartPage genericPage fadeQuick' id='cartPage'>


        {/* Body */}
        <div className='cartBody'>

            {/* Emapty? */}
            {props.cart.length <= 0 && <div className='innerAligner emptyCartText'>

                <h2>Your cart is empty.</h2>   

                <NormalButton 
                    text = {"Continue shopping"}
                    onClick = {() => {navigate('/product/observer_01')}}
                    complexStyle = {{
                        style: NormalButtonStyle.HollowWithAccents,
                        background: '',
                        foreground: "var(--ayisen-midnight)"
                    }}
                    customXPad = {90}
                />
                 

            </div>}

            {/* Error Finding Products */}
            {props.cart.length > 0 && cartTableItems.length <= 0 && <div className='innerAligner emptyCartText'>
                <h1>Error finding some cart items.</h1>   

                <NormalButton 
                    text = {"Continue shopping"}
                    onClick = {() => {navigate('/product/observer_01')}}
                    complexStyle = {{
                        style: NormalButtonStyle.HollowWithAccents,
                        background: '',
                        foreground: "var(--ayisen-midnight)"
                    }}
                    customXPad = {90}
                />
            </div>}


            {/* Something in it! */}
            {props.cart.length > 0 && cartTableItems.length > 0 && <>

                {/* Left Text */}
                <div className='cartBodyText'>

                    <div className='innerAligner'>

                        <h1> Your Cart </h1>
                        
                        {!props.user && <p className='cartAuthText'>
                            Returning customer? <a onClick={initLogin}>Click here</a> to log in. If you're new, you'll need to <a href='/create-account'>create an account</a> before purchasing.
                        </p>}

                    </div>

                </div>

                {/*  Right Info*/}
                <div className='cartBodyInfo'>

                    <div className='innerAligner'>

                        {/* Table */}
                        <CartTable 
                            items = {cartTableItems}
                            setItemCount = {props.setCartCount}
                        />

                        <div className='cartButtons'>

                            {/* Checkout , OR, Create Account Button */}
                            <div>   
                                {props.user ? <>
                                    <NormalButton 
                                        text = {"Checkout"}
                                        style={NormalButtonStyle.Dark1}
                                        onClick = {() => props.checkoutCart()}
                                    />
                                </>: <>
                                    <NormalButton 
                                        text = {"Create an account"}
                                        style={NormalButtonStyle.Dark1}
                                        onClick = {() => {navigate('/create-account')}}
                                    />
                                </>}
                                
                            </div>

                            {/* Continue Shopping Button */}
                            <div>
                                <NormalButton 
                                    text = {"Continue shopping"}
                                    complexStyle = {{
                                        style: NormalButtonStyle.HollowWithAccents,
                                        background: '',
                                        foreground: "var(--ayisen-midnight)"
                                    }}
                                    onClick = {() => {navigate('/product/observer_01')}}
                                />
                            </div>

                        </div>
                        
                        {!props.user && <>
                            <div className='disclaimer'>
                                Your Ayisen account lets you authorize, download and manage Ayisen products plus your included library content.
                            </div>
                        </>}


                    </div>


                </div>
            </>}


        </div>
        
    </div>


}

export default CartPage;