

export default (email: string) => {

    // No @ symbol
    if (!email.includes('@'))
        return {status: false, error: 'Email must contain "@" symbol.'}

    // Ensure of the format "anystring@anystring.anystring"
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    if (!re.test(email))
        return {status: false, error: 'Email must be of the form "john@smith.com".'}

    return {status: true, error: ''};

}