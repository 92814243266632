import React from 'react';

import '../../../styles/General/buttons.css';

interface CheckboxRowProps {

    value: boolean,
    text: string|JSX.Element, 
    onChange: (newBool: boolean) => any

}

export const CheckboxRow = (props: CheckboxRowProps) => {

    return <div className="checkboxRow innerAligner">

        <input 
            type="checkbox"
            checked = {props.value}
            onChange = {() => props.onChange(!props.value)}
        />
        <p>{props.text}</p>

    </div>



}

export default CheckboxRow;