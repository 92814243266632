
import React from 'react';
import { ModalState, ModalStateType, User } from '../../../constants/types';

import '../../../styles/General/Account/personalDetailEditor.css';
import NormalButton, { NormalButtonStyle } from '../Buttons/NormalButton';
import api from '../../../api';
import { toast } from 'react-toastify';
import StringComponents from '../String';

export interface PersonalDetailEditorProps {

    user: User,

    setModalState: (state: ModalState|null) => any,
    setUser: (user: User|null) => any,
    setLoading: Function,

};



export const PersonalDetailsEditor = (props: PersonalDetailEditorProps) => {


    const updateUserEmail = async (email: string) => {

        let reqBody = {
            diff: {
                email,
            }
        };

        // Post
        props.setLoading(true);
        const res = await api.post('user/update', reqBody, {}, true);
        props.setLoading(false);

        // Success?
        if (res && res.success)
            toast.info("A message has been sent to " + email + " to verify your new email address.");

        // Set user with updates
        if (res && res.user) {
            props.setUser(res.user);
        }

    }

    const updateUserName = async (name: string) => {

        let reqBody = {
            diff: {
                name,
            }
        };

        // Post
        props.setLoading(true);
        const res = await api.post('user/update', reqBody, {}, true);
        props.setLoading(false);

        // Success?
        if (res && res.success)
            toast.info("Your name has successfully been updated");

        // Set user with updates
        if (res && res.user) {
            props.setUser(res.user);
        }

    }

    const updateUserPassword = async (password: string, prevPassword: string) => {

        let reqBody = {
            previousPassword: prevPassword,
            diff: {
                password,
            }
        };

        // Post
        props.setLoading(true);
        const res = await api.post('user/update', reqBody, {}, true);
        props.setLoading(false);

        // Success?
        if (res && res.success)
            toast.info("Your password has successfully been updated");

        // Set user with updates
        if (res && res.user) {
            props.setUser(res.user);
        }

    }

    const initiateAccountDelete = async () => {
        props.setModalState({state: ModalStateType.DeleteAccount});
    }

    return <div className='fadeQuick'>

        <div className='personalInfoEditors'>

            <div className='personalInfoEditor'>

                <h3>Name</h3>

                <StringComponents.StringValEditor 
                    value = {props.user?.name}
                    updateValue = {(val) => updateUserName(val.value)}
                />

            </div>

            <div className='personalInfoEditor'>

                <h3>Email address</h3>

                <StringComponents.StringValEditor 
                    value = {props.user?.email}
                    updateValue = {(val) => updateUserEmail(val.value)}
                />

            </div>

            <div className='personalInfoEditor'>

                <h3>Password</h3>

                <StringComponents.StringValEditor 
                    value = {"*******"}
                    updateValue = {(val) => updateUserPassword(val.value, val.oldValue ?? '')}
                    isSecret = {true}
                    requireOldValPreviewText = {"Old password"}
                    valuePlaceholderText = {"New password"}
                    confirmationPreviewText = {"Confirm new password"}
                />
                
            </div>

            <div className='personalInfoEditor'>

                <h3>Delete Account</h3>

                <div style={{width: '100%'}}>
                    <NormalButton
                        text = {'Delete account'}
                        onClick = {initiateAccountDelete}
                        style = {NormalButtonStyle.Dark1}
                        fillWidth = {true}
                    />
                </div>
                
            </div>

        </div>


    </div>

}

export default PersonalDetailsEditor;