
import React from 'react';


import '../../../styles/General/Product/systemRequirements.css'
import { SystemRequirements } from '../../../constants/types';
import NormalButton, { NormalButtonStyle } from '../Buttons/NormalButton';
import utils from '../../../utilities';



interface SystemReqProps {

    requirements: SystemRequirements,
    userManual?: string,
    productName: string,

}

const SystemRequirementsComp = (props: SystemReqProps) => {   
    
    
    const downloadManual = () => {

        if (!props.userManual)
            return;

        utils.download.downloadLocalFile(props.userManual, props.productName + ".pdf");

    }

    return <div className='systemRequirementsWrapper'>

        {props.requirements.map(requirement => {

            return <div>

                {/* Title */}
                <h3>{requirement.title}</h3>   

                {/* Plain Text */}
                {requirement.plainText && <p>
                    {requirement.plainText}
                </p>} 

                {/* Bullet */}
                {requirement.bullets && requirement.bullets.map(bullet => {
                    return <li>{bullet}</li>
                })}

            </div>

        })}

        {/* Download User Manual */}
        <div style={{marginTop: 20}}/>
        {props?.userManual &&  <>
            <div style={{height: 20}}/>
            <NormalButton 
                text = {'Download Manual'}
                onClick = {downloadManual}
                complexStyle = {{
                    style: NormalButtonStyle.HollowWithAccents,
                    background: "",
                    foreground: "var(--ayisen-blue)"
                }}
        /></>}



    </div>


}

export default SystemRequirementsComp;