

export const contactEmail = "ayisenmain@gmail.com"

export const outputs = {


    contactEmail,

    getHeaderHeight: (pageWidth: number) => {

        if (pageWidth <= 800)
            return 0;

        return 0;

    }, 


    lightModeComponentIds: [
        
        "homePage-artistsCarousel",
        "cartPage",
        "requestPassResetPage",
        // "productPage",
        "aboutPage",
        "myAccountPage",
        "confirmEmailPage",
        "createAccountPage",
        "passResetPage",
        "textAndButtonPage",
        "purchaseCompletePage",
        "plainDocumentPage",

    ],

    whiteHeaderComponentIds: [


    ],



}

export default outputs;