
import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import Scrollbars from 'react-custom-scrollbars';
import { AllAddOnData, AllProductData, AllUserProducts, ModalState, User } from '../../constants/types';
import GeneralComponents from '../General';

import '../../styles/Pages/myAccount.css';
import ProductInstanceViewer from '../General/Account/ProductInstanceViewer';
import useWindowDimensions from '../../utilities/hooks/windowDims';

export interface MyAccountProps {

    user: User|null,
    userProducts: AllUserProducts,
    products: AllProductData,
    addOns: AllAddOnData,
    authTried: boolean,

    setModalState: (state: ModalState|null) => any,
    fetchUserProducts: () => any,
    setUser: (user: User|null) => any,
    setLoading: (val: boolean) => any,

};



export const MyAccountPage = (props: MyAccountProps) => {

    const {width: windowWidth, height: windowHeight} = useWindowDimensions();


    // On Mount 
    useEffect(() => {

        props.fetchUserProducts();

    }, [props.authTried]);

    
    // Sepecific Page
    const [searchParams] = useSearchParams();
    const page = searchParams.get('page');

    let bodyHeader = "My licenses & packs";
    if (page === 'personal-info')
        bodyHeader = "Personal details"
    else if (page === "support")
        bodyHeader = "Support"


    return <div className='myAccountPage fadeQuick' id='myAccountPage'>
        <div className='innerAligner'>

        <div className='accountMain'>

            {/* SideBar */}
            <GeneralComponents.Account.AccountSidebar 
                user = {props.user}
                setModalState = {props.setModalState}
            />

            <div className='myAccountBody'>

                {/* Header */}
                <h2>{bodyHeader}</h2>

                <Scrollbars 
                    autoHeight 
                    autoHeightMax={windowWidth < 600 ? '10000000000px' : 'calc(100vh - 400px)'}
                    renderView={props => <div style={{paddingRight: '15px', ...props.style}} />}
                >

                    {(!page || page === 'overview') && <>

                        <ProductInstanceViewer 
                            userProducts = {props.userProducts}
                            products = {props.products}
                            addOns = {props.addOns}
                            setLoading = {props.setLoading}
                        />
                    </>}

                    {page === 'personal-info' && props.user && <>

                        <GeneralComponents.Account.PersonalDetailEditor 
                            user = {props.user}
                            setModalState = {props.setModalState}
                            setUser = {props.setUser}
                            setLoading = {props.setLoading}
                        />
                    </>}

                    {page === 'support' && <>
                        <div className='fadeQuick'>

                        </div>
                    </>}

                </Scrollbars>


            </div>

        </div>
        </div>


    </div>


}

export default MyAccountPage;