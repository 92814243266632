
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { User } from '../../constants/types';


import '../../styles/footer.css'


export interface FooterProps {

    user: User|null,

}

export const Footer = (props: FooterProps) => {

    const navigate = useNavigate();

    return (

        <div className='footer'>

            <div className='innerAligner'>

                {/* Logo Section */}
                <div className='logoSection'>

                    {/* Logo */}
                    <img src='/resources/AyisenLogo/LogoCreme.png' alt='AYISEN' onClick={() => navigate('/')}></img>


                </div>

                <div className='footerBody'>
                    

                    {/* Company */}
                    <div className='linkSection'>

                        <h3>Explore</h3>
                        <div onClick={() => navigate("product/observer_01")}>Observer_01</div>
                        <div onClick={() => navigate('about')}>About us</div>
                        <div onClick={() => navigate('terms-and-conditions')}>Terms & Conditions</div>
                        <div onClick={() => navigate('policy')}>Privacy Policy</div>
                        <div onClick={() => navigate('cookie-policy')}>Cookies</div>
                        <div onClick={() => navigate('refunds')}>Refunds</div>
                        <div onClick={() => navigate('license-agreement')}>License</div>
                        

                    </div>

                    {/* Company */}
                    <div className='linkSection'>

                        <h3>Account</h3>
                        {props.user && <>
                            <div onClick={() => navigate("my-account?page=personal-info")}>Personal details</div>
                            <div onClick={() => navigate("my-account?page=overview")}>My products</div>
                        </>}

                        <div onClick={() => navigate("cart")}>Cart</div>

                    </div>

                    {/* Company */}
                    <div className='linkSection'>

                        <h3>Social</h3>
                        <div onClick={() => {window.location.href ='https://www.instagram.com/ayisen_01/'}}>Instagram</div>
                        <div onClick={() => {window.location.href ='https://www.twitter.com/ayisen_01/'}}>Twitter</div>
                        <div onClick={() => {window.location.href ='https://www.facebook.com/ayisen_01/'}}>Facebook</div>
                        

                    </div>

                </div>

                <div className='footerFooter'>

                    <div>@ Ayisen 2022</div>
                    

                </div>

            </div>



        </div>


    )


}

export default Footer;