
const TEST_STRIPE_PUBLIC_KEY = 'pk_test_51IKEmsFyDCGBB3yWwfnWiiBLESX1WKeDcaW1gYqaQS7sr0UIKtkdUy8w5R0oLEZQNNtlC2JcCLy0ITDZTNSUBGWl00ysrP9iRY';
const PRODUCTION_STRIPE_PUBLIC_KEY = 'pk_live_51IKEmsFyDCGBB3yW7TMCqDujRVoyXnNol9QbSPDtKsfPOi4LXvGiuJpo5IIvasHgEfCiwg0q6RcKOzoJUdkwnUDL00EJUetyxx';


export const getStripePublicKey = () => {

    // Get host
    const hostname = window.location.hostname;

    // Local? 
    if (hostname.includes('localhost')) {
        return TEST_STRIPE_PUBLIC_KEY;
    }

    // Staging? 
    else if (hostname.includes('staging')) {
        return TEST_STRIPE_PUBLIC_KEY;
    }

    // Production? 
    else {
        return PRODUCTION_STRIPE_PUBLIC_KEY;
    }


}

export default getStripePublicKey;