

import React from 'react';


import '../../../styles/General/stringInput.css'

export interface StringInputProps {

    value: string,
    editValue: (string: string) => any,

    disabled?: boolean,
    isSecret?: boolean,
    placeholder?: string,

    isMultiLine?: boolean,
    rightHandText?: string,

}

export const StringInput = (props: StringInputProps) => {


    return <div className='stringInputWrapper'>

        {props.isMultiLine ? <>

            <textarea 
                className="stringInput"
                value={props.value}
                disabled = {props.disabled ? true : false}
                onChange = {(e) => props.editValue(e.target.value)}
                placeholder = {props.placeholder ?? ''}
                rows = {4}
            />
        
        </> : <div style={{position: 'relative'}}>

            <input 
                className="stringInput"
                value={props.value}
                disabled = {props.disabled ? true : false}
                onChange = {(e) => props.editValue(e.target.value)}
                type={props.isSecret ? 'password' : 'text'}
                placeholder = {props.placeholder ?? ''}
            />

            {props.rightHandText && <div className='rightHandText'>
                {props.rightHandText}
            </div>}
        
        </div>}
    
    
    </div> 


}

export default StringInput;