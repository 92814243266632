
import React from 'react';
import { useNavigate } from 'react-router-dom';


import '../../../styles/General/Product/purchaseHero.css'
import { ModalState, ModalStateType, ProductData, User, OsType } from '../../../constants/types';
import Buttons from '../Buttons';
import { NormalButtonStyle } from '../Buttons/NormalButton';

import utilities from '../../../utilities';
import PriceTag from '../String/PriceTag';
import product from '../../../utilities/product';
import api from '../../../api';



interface PurchaseHeroProps {

    product: ProductData,
    cartCount: number, 
    setCartCount: (value: number) => any,
    goToSystemRequirements: () => any,
    setModalState: (state: ModalState) => any,
    setLoading: (val: boolean) => any,
    user: User|null,

}

const PurchaseHero = (props: PurchaseHeroProps) => {

    const navigate = useNavigate();

    // TODo @Marcel: Make this a utility
    const downloadLatestVersion = async (os: OsType) => {

        // Construct URL 
        let url = 'product/download/' + props.product.details.uid;

        // OS Extension? 
        if (os === OsType.MAC) {
            url += "/mac";
        }
        else if (os === OsType.WINDOWS) {
            url += "/windows";
        }
        else {
            url += "/universal";
        }

        // Download plugin file
        props.setLoading(true);
        await api.getDownloadPresigned(url, {}, true);
        props.setLoading(false);
        

    }

    const tryForFree = (opt: string) => {

        // Is the user logged in? 
        if (!props.user) {

            return props.setModalState({
                state: ModalStateType.Login,
                message: "An account is required in order to start your free trial.",
            });
            
        }

        // TODO @Marcel: This is hard coded to display both windows and mac downloads. It needs to dynamically know whether these are both available!

        let osType = OsType.MAC;
        if (opt === "Windows")
            osType = OsType.WINDOWS;

        downloadLatestVersion(osType);

        



    }
    

    // For sale? 
    const pricingData = utilities.price.getDisplayPrice(props.product.price, props.product.salePrice);

    // Can use trial 
    let trialAvailable = false;
    if (props.product?.details?.trialPeriod) {


        if (!props.user?.productTrialsUsed) {
            trialAvailable = true;
        }

        else {

            if (!props.user.productTrialsUsed.includes(props.product?.details._id))
                trialAvailable = true;

        }

    } 


    return <div className='purchaseHeroWrapper'>

        {/* Image For Mobile (not inner aligned, wall to wall) */}

        <div className='purchaseHero innerAligner'>

            {/* Image */}
            <div className='pHeroImg'>

                {/* TODO @marcel @postlaunch: Load from product again */}
                <img src={"/resources/Background/Product/ObserverHeader.png"} alt='' />
            </div>

            {/* Text Area */}
            <div className='pHeroTextArea'>

                <div className='pHeroTextAInner'>

                    <h1>{props.product.name}</h1>

                    <p>
                        {props.product.purchaseHero.description}
                    </p>

                    {/* Price */}
                    <PriceTag
                        price = {props.product.price}
                        salePrice = {props.product.salePrice}
                    />
                    

                    <div className='saleButtonRow'>
                        {pricingData.forSale && <>
                            <Buttons.NormalButton 
                                text = {'Add to cart'}
                                onClick = {() => {
                                    props.setCartCount(props.cartCount + 1);
                                    navigate('/cart')
                                }}
                                complexStyle = {{
                                    style: NormalButtonStyle.SolidWithAccents,
                                    background: "var(--ayisen-dusk)",
                                    foreground: "var(--ayisen-blue)"
                                }}
                                // blockWidenForMobile = {true}
                            />
                        </>}

                        {trialAvailable && <Buttons.NormalButton 
                            text = {'Download free trial'}
                            options = {["Windows", "Mac"]}
                            onClick = {() => {}}
                            optClick = {tryForFree}
                            complexStyle = {{
                                style: NormalButtonStyle.HollowWithAccents,
                                background: "",
                                foreground: "var(--ayisen-dusk)"
                            }}
                            // blockWidenForMobile = {true}
                        />}
                    </div>

                    <div className='heroMinorLinks'>
                        <div 
                            className='heroMinorLink'
                            onClick={props.goToSystemRequirements}
                        >
                            System requirements
                        </div>
                        <div 
                            className='heroMinorLink'
                            onClick={() => {navigate('/license-agreement')}}
                        >
                            End User License Agreement
                        </div>
                    </div>

                </div>

            </div>

        </div>
        
        
    </div>


}

export default PurchaseHero;