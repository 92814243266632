import String from "./String";
import Product from "./Product";
import Account from "./Account";
import Dropdowns from "./Dropdown";

export default {
    String,
    Product,
    Account,
    Dropdowns,
}