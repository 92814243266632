
import React from 'react';
import artists from '../../../constants/artists';

import '../../../styles/Pages/home.css';
import ArtistProfileBubble from '../../General/Artist/ArtistProfileBubble';
import GeneralCarousel from '../../General/Carousels/GenericCarousel';
import Subscribe from '../../General/MailChimp/Subscribe';
import { useState } from 'react';
import ScrollFollower from '../../Utility/ScrollFollower';
import HomeAnimations from './HomeAnimations';
import useWindowDimensions from '../../../utilities/hooks/windowDims';
import HomeAnimationsMobile from './HomeAnimationsMobile';


const ringsImgs = [

    "resources/Background/RingsOrange.png",
    "resources/Background/RingsBlue.png",
    "resources/Background/RingsGold2.png",
    "resources/Background/RingsGreen.png",

]


export const HomePage = () => {


    // eslint-disable-next-line
    const [ scrollPercentS, setScrollPercentS ] = useState(0);
    const [ activeArtist, setActiveArtist ] = useState(0);

    const {width: windowWidth, height: windowHeight} = useWindowDimensions();


    // const scrollPercent = scrollPercentS;
    // const rotAngle = 55 + (-55 * (scrollPercent));


    return <div className='homePage fadeQuick'>

        {/* Scroll Follower for the Image Rotation */}
        <ScrollFollower
            elemId='observerSection1'
            setScrollPercent={setScrollPercentS}
        />

        <div className='homeBannerWrapper'>
            <div className='browserOnly'>
                <img 
                    className='homeBannerTop'
                    src='/resources/Background/Petals/TopOrange.png'
                    alt = ''
                />
                <img 
                    className='homeBannerBottom'
                    src='/resources/Background/Petals/BottomBlue.png'
                    alt = ''
                />

            </div>
            <div className='mobileOnly'>
                <img 
                    className='homeBannerTop'
                    src='/resources/Background/Petals/TopOrangeM.png'
                    alt = ''
                />
                <img 
                    className='homeBannerBottom'
                    src='/resources/Background/Petals/BottomBlueM.png'
                    alt = ''
                />
            </div>

            <div className='bannerTxtRow'>
                Find your rhythm.
            </div>
        </div>

        <div className=''>


            <div className='pageSection spotlightSectionWrapper'>

                {(windowWidth <= 600) ? <HomeAnimationsMobile/> : <HomeAnimations/>}

            </div>


            {/* <div className='pageSection artistsSectionWrapper' id="homePage-artistsCarousel">

                <div className='innerAligner' style={{maxWidth: 650, paddingBottom: 50}}>
                    <h2>We want to help you make music that sounds like you.</h2>

                    <p>Here are just a few of the artists that have used Observer_01 to expand their sound and creative process.</p>

                </div>

                <div className='innerAligner' style={{width: '100vw', maxWidth: "100vw", overflow: 'hidden'}}>
                    <div className='artistsCarousel'>
                        <GeneralCarousel
                            automated={true}
                            infiniteLoop={true}
                            automationInterval={3}
                            showSlivers = {true}
                            numDesktopCards = {4}
                            onIdxChange = {setActiveArtist}
                        >
                            {artists.map((artistInfo, idx) => {

                                const ringImg = ringsImgs[idx%ringsImgs.length];

                                
                                const activeIdx = activeArtist % artists.length;
                                console.log("Active Art: ", activeIdx);

                                const nextActive = (activeArtist + 1) % artists.length;
                                const thirdActive = (activeArtist + 2) % artists.length;
                                const fourthActive = (activeArtist + 3) % artists.length;

                                const style: any = {width: 0};
                                if (idx === activeIdx) {
                                    style.left = "-50%";
                                    // style.top = "35%";
                                    style.width = "170%";
                                    style.zIndex = 5;
                                }
                                else if (idx === nextActive) {
                                    style.left = "-10%";
                                    style.width = "120%";
                                    style.zIndex = 4;
                                }
                                else if (idx === thirdActive) {
                                    style.left = "-20%";
                                    style.width = "110%";
                                    style.zIndex = 3;
                                }
                                else if (idx === fourthActive) {
                                    style.width = "60%";
                                    style.zIndex = 2;
                                }


                                return <div 
                                    className='artistBubbleOutter' 
                                    // style={{
                                    //     ...style,
                                    // }}
                                >
                                    <ArtistProfileBubble artist={artistInfo} backgroundImg={ringImg}/>
                                </div>
                            })}
                        </GeneralCarousel>
                    </div>
                </div>
                
                


            </div> */}

            <div className='pageSection mailingListWrapper'>
                <div className='mailingListBackground'/>
                <div className='innerAligner' style={{maxWidth: 600}}>
                    <Subscribe
                    />
                </div>
            </div>

        </div>

    </div>


}

export default HomePage;