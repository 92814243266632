import { User } from "../../constants/types";

import allowedAvatars from "./allowedAvatars";

export default (user: User|undefined|null) => {

    let avatar = user?.avatarId ?? "5";

    if (!allowedAvatars.includes(avatar))
        avatar = "5";

    return ("/resources/Avatars/" + avatar + ".png");

}