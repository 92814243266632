
import React from 'react';
import { useNavigate } from 'react-router-dom';

import '../../styles/Pages/textAndButton.css';
import NormalButton, { NormalButtonStyle } from '../General/Buttons/NormalButton';
import GenericPageWrapper from './GenericPageWrapper';


export interface TextAndButtonProps {

    title: string,
    buttonText: string,
    buttonClick: () => any,

}


export const TextAndButtonPage = (props: TextAndButtonProps) => {

    const navigate = useNavigate();

    return <GenericPageWrapper><div className='textAndButtonPage fadeQuick' id="textAndButtonPage">

        <div className='innerAligner textAndButtonWrapper'>

            <h2>{props.title}</h2>

            <NormalButton
                text={props.buttonText}
                onClick={props.buttonClick}
                complexStyle = {{
                    style: NormalButtonStyle.HollowWithAccents,
                    background: "",
                    foreground: "var(--ayisen-midnight)"
                }}
                // fillWidth = {true}
            />

        </div>

    </div></GenericPageWrapper>


}

export default TextAndButtonPage;