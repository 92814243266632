import { ProdAddOnData, ProductData } from "../../constants/types";




export const getOssWithDownloadAvail = (product: ProductData|ProdAddOnData) => {

     const versions = product?.details?.versions;
     let windowsDownload = false;
     let macDownload = false;
     let universalDownload = false;
     if (versions && versions.length > 0) {
 
         // Get last version 
         const lastVersion = versions[versions.length - 1];
 
         // What support 
         if (lastVersion?.systemDownloadables?.mac)
             macDownload = true;
         if (lastVersion?.systemDownloadables?.windows)
             windowsDownload = true;
         if (lastVersion?.systemDownloadables?.all)
             universalDownload = true;
 
     }

     return {
         windowsDownload,
         macDownload,
         universalDownload,
     }


}