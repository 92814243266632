import React from "react";

import "../../../styles/General/mailchimp.css";
import '../../../styles/General/stringInput.css';
import '../../../styles/General/buttons.css';

export const Subscribe = () => { 

    const subscribe = true;

    const mailchimpLink = "https://Ayisen.us7.list-manage.com/" + (subscribe ? 'subscribe' : 'unsubscribe') + "/post?u=8524319cc4217611ca8175fbb&amp;id=99a5b5e3cf";
    const submitText = (subscribe ? "Submit" : "Unsubscribe");

    return <div className="mailChimpSubscribe">

        <div className="mailChimpHead">
            <h2 style={{paddingBottom: 10}}>Stay in the loop</h2>
            <p style={{opacity: 0.6 }}>Enter your email below to get updated on our projects and special offers.</p>
        </div>

        <link href="//cdn-images.mailchimp.com/embedcode/classic-10_7_dtp.css" rel="stylesheet" type="text/css"/>
        
        <div>
        <form action={mailchimpLink} method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" noValidate>
            <div id="mc_embed_signup_scroll">
        <div className="inputRow innerAligner">
            <input placeholder="Your email" type="email" name="EMAIL" className="stringInput" id="mce-EMAIL"/>
            <input type="submit" value={submitText} name="subscribe" className="normalButton submitButton"/>
        </div>
        <div hidden={true}><input type="hidden" name="tags" value="6747433"/></div>
            <div id="mce-responses" className="clear foot">
                <div className="response" id="mce-error-response" style={{display:'none'}}></div>
                <div className="response" id="mce-success-response" style={{display:'none'}}></div>
            </div>    
            <div style={{position: "absolute", left: "-5000px"}} aria-hidden="true"><input type="text" name="b_8524319cc4217611ca8175fbb_99a5b5e3cf" tabIndex={-1} value=""/></div>
                <div className="optionalParent">
                </div>
            </div>
        </form>
        </div>
        

    </div>


}

export default Subscribe
