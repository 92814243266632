
import React from 'react';

import IntegerEditor from '../Number/IntegerEditor';
import utilities from '../../../utilities';

import '../../../styles/General/cartTable.css'


export interface CartTableRowProps {
    uid: string
    name: string, 
    quantity: number, 
    price: number, 
    salePrice?: number,
}

interface CartTableProps {
    items: CartTableRowProps[],

    setItemCount: (productUid: string, count: number) => any,
}

const CartTable = (props: CartTableProps) => {


    // Calc subtotal
    let subtotal = props.items.reduce((partialSum, item) => {
        const itemPrice = (item.salePrice || item.salePrice ===0) ? item.salePrice : item.price;
        const itemSub = (itemPrice * item.quantity);
        return (partialSum + itemSub);
    }, 0);

    subtotal = utilities.price.roundToCents(subtotal);


    return <div className='cartTable'>


        <div className='cartRow cartHeaderRow'>

            <div>Product</div>
            <div>Quantity</div>
            <div>Price</div>

        </div>

        {props.items.map(item => {

            const finalPrice = ((item.salePrice || (item.salePrice === 0)) ? item.salePrice : item.price);

            const itemsTotal = utilities.price.roundToCents(( finalPrice * item.quantity));
            
            return <div className='cartRow cartItemRow'>

                <div>{item.name}</div>
                <IntegerEditor 
                    value={item.quantity} 
                    updateNumber = {(v) => {props.setItemCount(item.uid, v)}}
                />
                <div>{"$" + itemsTotal}</div>
            </div>
        })}

        

        <div className='cartRow subtotalRow'>

            <div>Subtotal</div>
            <div/>
            <div>{"$" + subtotal}</div>
        </div>

        <p className='cartTotalHint'>Your total will be calculated at Stripe checkout</p>

        
    </div>


}

export default CartTable;