
import React, { useState } from 'react';
import { ModalState } from '../../constants/types';

import '../../styles/Modals/deleteAccount.css';
import NormalButton, {NormalButtonStyle} from '../General/Buttons/NormalButton';
import StringInput from '../General/String/StringInput';



export interface DeleteAccountProps {
    setModalState: (state: ModalState|null) => any,
    deleteAccount: (password: string) => any,
}

export const DeleteAccountModalBody = (props: DeleteAccountProps) => {

    const [ password, setPassword ] = useState('');


    return <div className='deleteAccountBody'>

        <h3>Are you sure you want to delete your account?</h3>

        <p className='modalMessage'>This action cannot be reversed</p>

        

        <div className='buttonSet'>

            <StringInput 
                value={password}
                editValue={setPassword}
                isSecret = {true}
                placeholder = {"Enter your password"}
            />

            <NormalButton 
                text = {"No, don't delete my account"}
                onClick = {() => props.setModalState(null)}
                style = {NormalButtonStyle.LightWithBorder1}
                fillWidth = {true}
            />

            <NormalButton 
                text = {"Yes, delete my account"}
                onClick = {() => props.deleteAccount(password)}
                style = {NormalButtonStyle.DarkWithBorder1}
                fillWidth = {true}
            />

        </div>

    </div>


}



export default DeleteAccountModalBody;