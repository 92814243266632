
import React from 'react';

import '../../../styles/General/Product/presetCarosel.css'
import { ProdAddOnData } from '../../../constants/types';
import NormalButton, { NormalButtonStyle } from '../Buttons/NormalButton';
import PriceTag from '../String/PriceTag';
import { useNavigate } from 'react-router-dom';



export interface PresetCardProps {

    addOn: ProdAddOnData,
    setCartCount: (value: number) => any,
    cartCount: number,

}

export const PresetCard = (props: PresetCardProps) => {

    const navigate = useNavigate();

    return <div className='presetCard'>

        {/* Thumbnail */}
        <img 
            src = {props.addOn.thumbnail}
            alt = ""
        />

        <div className='infoDiv'>

             {/* Name */}
            <h2>{props.addOn.name}</h2>
            <div>{props.addOn.description}</div>

            {/* Price */}
            <PriceTag 
                price = {props.addOn.price}
                salePrice = {props.addOn.salePrice}
            />
            
            <NormalButton 
                text={props.addOn.price ? 'Add to cart' : 'Not for sale'}
                onClick={() => {
                    props.setCartCount(props.cartCount + 1);
                    navigate('/cart');
                }}
                style = {NormalButtonStyle.LightWithBorder1}
                disabled = {!props.addOn.price}
            />

        </div>

       

    </div>


}

export default PresetCard;
