
import useEffectOnce from "./useEffectOnce";
import useWindowDimensions from "./windowDims";

const hooks = {
    
    useEffectOnce,
    useWindowDimensions,

}


export default hooks;