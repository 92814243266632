
import { ArtistTestimonial } from "./types";


const artists: ArtistTestimonial[] = [

    {
        name: "Tim Burton",
        testimonial: "It was... exquisite.",
        icon: "/resources/Artists/Default.png"
    },

    {
        name: "SpongeBob",
        testimonial: "Hahahahaha!",
        icon: "/resources/Artists/Default.png"
    },

    {
        name: "Pinhead",
        testimonial: "Give me pain.",
        icon: "/resources/Artists/Default.png"
    },

    {
        name: "Sufjan Stevens",
        testimonial: "It was... exquisite.",
        icon: "/resources/Artists/Default.png"
    },

    {
        name: "Orpheus",
        testimonial: "Unlike anything I've ever seen.",
        icon: "/resources/Artists/Default.png"
    },

    // {
    //     name: "Tim Burton",
    //     testimonial: "It was... exquisite.",
    //     icon: "/resources/AyisenLogo/IconBlack.png"
    // },

];

export default artists;