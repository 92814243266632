
import { AllAddOnData, AllProductData, ProductData } from "../types";


export const Go_01Data: ProductData = {

    name : 'GO!_01',
    uid: 'go!_01',

    purchaseHero: {
        icon: "/resources/Products/Observer_1/purchaseHero.png",
        description: "Geometric orbital sequencer",
    },

    productFeatures: [

    ],

    banner : '/resources/Products/goPlaceHolderPhoto.jpg',
    thumbnail : '/resources/Products/goPlaceHolderPhoto.jpg',
    littleIcon : '/resources/AyisenLogo/IconBlack.png',

    description : 'KEY TO THE FREAK ZONE.',

    staticPageLink : '/products/go_01',

    price : undefined,
    
};