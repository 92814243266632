
import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ModalState, ModalStateType, User } from '../../../constants/types';

import '../../../styles/General/Account/accountSidebar.css';
import utils from '../../../utilities';


export interface AccountSidebarProps {

    user: User|null,
    setModalState: (state: ModalState|null) => any,

};

const links = [

    {
        text: "Account overview",
        page: 'overview',
    },
    {
        text: "Personal details",
        page: 'personal-info',
    },
    // {
    //     text: "Support", 
    //     page: 'support'
    // }

]


export const AccountSidebar = (props: AccountSidebarProps) => {

    const navigate = useNavigate();
    
    // Sepecific Page
    const [searchParams] = useSearchParams();
    const page = searchParams.get('page');

    // Current Path 
    const curPath = window.location.pathname.split('?')[0];


    return <div className='myAccountNav'>

        {/* #Tag: Enhancement: Avatar */}
        <img 
            src={utils.user.getAvatar(props.user)}
            alt = "User"
            onClick={() => props.setModalState({state: ModalStateType.ChangeAvatar})}
        />

        <div className='userEmail'>
            {props.user?.email}
        </div>

        {/* Links */}
        <div>
            {links.map(linkData => {
                return <div
                    className='accountOpt'
                    onClick={() => navigate(curPath + '?page=' + linkData.page)}
                >
                    {linkData.text}
                </div>
            })}
        </div>

    </div>

}

export default AccountSidebar;