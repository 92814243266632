
import product from "./product";
import cart from "./cart";
import verify from "./verify";
import price from "./price";
import hooks from "./hooks";
import user from "./user";
import string from "./string";
import dom from "./dom";
import download from "./download";

const utils = {
    product,
    cart,
    verify,
    price,
    hooks,
    user,
    string,
    dom,
    download,
}

export default utils;
