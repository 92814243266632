
import React, { useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';


import '../../../styles/General/buttons.css'

export enum NormalButtonStyle {

    SimpleWhiteText = 1,
    Dark1,
    DarkWithBorder1,
    LightWithBorder1,
    CremeTextCremeBorder,
    SolidWithAccents,
    HollowWithAccents,
}

export interface NormalButtonProps {
    text: string,
    onClick: () => any,

    // Optional 
    style?: NormalButtonStyle,
    complexStyle?: {
        style: NormalButtonStyle,
        background: string,
        foreground: string,
    }

    blockWidenForMobile?: boolean,
    customXPad?: number,
    fillWidth?: boolean,
    disabled?: boolean,
    slim?: boolean,
    options?: string[],
    optClick?: (val: string) => any,
}

export const NormalButton = (props: NormalButtonProps) => {

    const [ optionsOpen, setOptionsOpen ] = useState(false);


    const optClick = (opt: string) => {

        if (props.optClick)
            props.optClick(opt);

    }

    // Styles 
    let styles: any = {}

    // Schemes

    if (props.complexStyle) {

        if (props.complexStyle.style === NormalButtonStyle.SolidWithAccents) {

            styles.backgroundColor = props.complexStyle.background;
            styles.color = props.complexStyle.foreground
            styles['-webkit-text-fill-color'] = props.complexStyle.foreground;

        }

        else if (props.complexStyle.style === NormalButtonStyle.HollowWithAccents) {

            styles.backgroundColor = "rgba(0,0,0,0)";
            styles.color = props.complexStyle.foreground
            styles['-webkit-text-fill-color'] = props.complexStyle.foreground;
            styles.outline = '2px solid ' + props.complexStyle.foreground;

        }


    } 

    else if (props.style) {

        switch (props.style) {
            
            case (NormalButtonStyle.Dark1): {
                styles.backgroundColor = 'var(--ayisen-gray-1)';
                styles.color = 'var(--ayisen-purple-5)'
                styles['-webkit-text-fill-color'] = "var(--ayisen-purple-5)";
                break;
            }
            case (NormalButtonStyle.DarkWithBorder1): {
                styles.backgroundColor = 'rgba(51, 51, 51, 0)';
                styles.color = 'var(--ayisen-gray-6)';
                styles['-webkit-text-fill-color'] = "var(--ayisen-gray-6)";
                styles.outline = '2px solid var(--ayisen-gray-6)';
                break;
            }
            case (NormalButtonStyle.LightWithBorder1): {
                styles.color = 'var(--ayisen-gray-1)';
                styles.outline = '2px solid rgba(0,0,0,1)';
                styles.backgroundColor = 'rgba(255, 255, 255, 1)';
                break;
            }
            case(NormalButtonStyle.SimpleWhiteText): {
                styles.color = 'white';
                styles['-webkit-text-fill-color'] = "white";
                break;
            }
            case(NormalButtonStyle.CremeTextCremeBorder): {
                styles.color = 'var(--ayisen-creme_';
                styles['-webkit-text-fill-color'] = "var(--ayisen-creme)";
                styles.outline = "2px solid var(--ayisen-creme)";
                break;
            }
    
            default: {
                break;
            }
        }

    }

    

    if (props.customXPad) {
        styles.paddingLeft = props.customXPad;
        styles.paddingRight = props.customXPad;
    }

    if (props.disabled) {
        styles.opacity = 0.6;
    }


    return <button
        className={'normalButton' + (!props.blockWidenForMobile ? " buttonWidenModile" : "") + (props.fillWidth ? " buttonFillWidth" : "") + (props.slim ? " slimButton" : "")}
        onClick = {() => {

            if (props.disabled)
                return;

            if (props.onClick)
                props.onClick()

            if (props.options)
                setOptionsOpen(!optionsOpen);

        }}
        style = {styles}
    >
        {props.text}

        {props.options && optionsOpen && <OutsideClickHandler
            onOutsideClick={() => setOptionsOpen(false)}
        > 
            <div className='optsWrapper'>

                {props.options.map((opt, idx) => {

                    return <div 
                        style={{
                            ...styles,
                            outline: undefined,
                            backgroundColor: undefined,
                            border: undefined
                        }} 
                        onClick={() => optClick(opt)}
                    >
                        {opt}
                    </div>

                })}

                
            </div>
        </OutsideClickHandler>}

    </button>

}

export default NormalButton;