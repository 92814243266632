
import React, { useEffect } from "react";
import utils from "../../../utilities";

// Init Google Analytics
import ReactGA from 'react-ga';
import ReactFacebookPixel from 'react-facebook-pixel';
import useEffectOnce from "../../../utilities/hooks/useEffectOnce";
import { useLocation } from "react-router-dom";

ReactGA.initialize(utils.string.getGoogleAnalyticsId());
ReactFacebookPixel.init(utils.string.getFacebookPixelId(), undefined, {autoConfig: true, debug: false});

interface AnalyticsWapperProps {
    children: any
} 

export const AnalyticsWrapper = (props: AnalyticsWapperProps) => {

    // Mount
    useEffectOnce(() => {

        // Track Page
        ReactGA.pageview(window.location.pathname + window.location.search); 
        ReactFacebookPixel.pageView();

    });

    // Page Change 
    let location = useLocation();
    useEffect(() => {

        // Track Page
        ReactGA.pageview(window.location.pathname + window.location.search); 
        ReactFacebookPixel.pageView();

    }, [location]);


    return <>

        {/* Render Children */}
        {props.children}

    </>


}


export default AnalyticsWrapper;