

import React, {useState} from 'react';


import '../../../styles/General/Dropdown/simpleDropdown.css'

export interface SimpleDropProps {

    // Title
    title: string,

    children: any
}

export const SimpleDropdown = (props: SimpleDropProps) => {

    const [isOpen, setIsOpen] = useState(false);

    return <div className={"simpleDropdown" + (isOpen ? " simpleDropdownOpen" : "")}>

        {/* Header */}
        <div className='dropHeader' style={{borderColor: "var(--ayisen-blue)"}} onClick={() => {setIsOpen(!isOpen)}}>

            <h2>{props.title}</h2>
            <img src="/resources/Icons/down.png"/>

        </div>

        <div className='dropBody'>
            {props.children}
        </div>



    </div>


}

export default SimpleDropdown;