import React from "react";
import { AllProductData, ProdAddOnData, UserProduct } from "../../../constants/types";
import utils from "../../../utilities";
import ProductInstanceCard from "./ProductInstanceCard";


interface AddOnInstanceCardProps {

    products: AllProductData,
    productInstance: UserProduct,
    product: ProdAddOnData,
    setLoading: (val: boolean) => any,

}

export const AddOnInstanceCard = (props: AddOnInstanceCardProps) => {
    
    // Get parent product
    const parentProduct = utils.product.getParentProduct(props.product, props.products);
    const nameExtension = "(" + (parentProduct?.name ?? "Plugin ") + " add on)"; 

    return <ProductInstanceCard
        product={props.product}
        productInstance = {props.productInstance}
        setLoading = {props.setLoading}
        nameExtension = {nameExtension}
    >


    </ProductInstanceCard>

}

export default AddOnInstanceCard;