
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ModalState } from '../../constants/types';

import '../../styles/Modals/login.css';
import NormalButton, {NormalButtonStyle} from '../General/Buttons/NormalButton';
import StringInput from '../General/String/StringInput';



export interface LoginModalBodyProps {
    message?: string,
    login: (email: string, password: string) => any,
    setModalState: (state: ModalState|null) => any,
}

export const LoginModalBody = (props: LoginModalBodyProps) => {

    // State 
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const navigate = useNavigate();


    return <div className='loginBody'>

        <h2>Login or sign up</h2>

        {/* Specific Message */}
        {props.message && <p className='modalMessage'>
            {props.message}
        </p>}

        <div className='inputSet'>

            {/* Login Form */}
            <StringInput 
                value = {email}
                editValue = {v => setEmail(v)}
                placeholder = {'Your email'}
                rightHandText = {"@"}
            />

            <StringInput 
                value = {password}
                editValue = {v => setPassword(v)}
                placeholder = {'Your password'}
                isSecret = {true}
            />

            <NormalButton 
                text = {"Login"}
                onClick = {() => props.login(email, password)}
                style = {NormalButtonStyle.DarkWithBorder1}
                fillWidth = {true}
            />

            <p className='orText'>or</p>

            <NormalButton 
                text = {"Sign Up"}
                onClick = {() => {
                    props.setModalState(null);
                    navigate('/create-account');
                }}
                style = {NormalButtonStyle.LightWithBorder1}
                fillWidth = {true}
            />

            <NormalButton 
                text = {"Need help?"}
                onClick = {() => {
                    props.setModalState(null);
                    navigate('/request-reset-password');
                }}
                fillWidth = {true}
                style = {NormalButtonStyle.SimpleWhiteText}
            />

        </div>

        

    </div>


}


export default LoginModalBody;