
import React from 'react';
import utilities from '../../../utilities';
import '../../../styles/General/string.css';

interface PriceTagProps {

    price?: number,
    salePrice?: number,

}

export const PriceTag = (props: PriceTagProps) => {

    let { onSale, displayPrice, displaySalePrice } = utilities.price.getDisplayPrice(props.price, props.salePrice);

    return <>

        {/* Price */}
        <div className={'priceRow' + ( onSale ? ' priceRowSale' : '' ) }>
            <p className='mainPrice'>{displayPrice === undefined ? "Not for sale" : "$" + displayPrice}</p>
            {onSale && <p className='salePrice'>
                {"$" + displaySalePrice}
            </p>}
        </div>

    </>


}

export default PriceTag;