


export default async (url, fileName, mimeType = 'application/octet-stream') => {

    // Create an element to download from 
    let a = document.createElement('a');
    a.download = fileName;
    a.href = url;
    a.click();

    // Clean up
    window.URL.revokeObjectURL(a.href);

}