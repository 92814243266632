
import { Cart } from "../../constants/types";

export default (cart: Cart) => {

    const simpleCart = cart.map(e => {
        return {uid: e.uid, count: e.count}
    });

    localStorage.setItem('ayisenCart', JSON.stringify(simpleCart));

}