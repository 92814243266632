import LicenseAcreementPage from "./LicenseAgreement";
import PolicyPage from './Policy';
import TermsAndConditions from "./TermsAndConditions";
import CookiePolicyPage from "./CookiePolicy";
import Refunds from "./Refunds";

const pages = {
    LicenseAcreementPage,
    PolicyPage,
    TermsAndConditions,
    CookiePolicyPage,
    Refunds,
}

export default pages;