import { User } from "../../constants/types";
import allowedAvatars from "./allowedAvatars";

export default () => {

    return allowedAvatars.map(avatarId => {
        return {
            id: avatarId,
            icon: ("/resources/Avatars/" + avatarId + ".png")
        }
        
    });

}