import React from 'react';

import "../../styles/ayisen.css";

export interface GenericPageWrapperProps {

    children: any,

}

export const GenericPageWrapper = (props: GenericPageWrapperProps) => {

    return <div className='genericPage fadeQuick'>

        {props.children}

    </div>


}

export default GenericPageWrapper