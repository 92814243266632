
import { AllAddOnData, AllProductData, ProductData } from "../types";


export const Ripple_01Data: ProductData = {

    name : 'RIPPLE_01',
    uid: 'ripple_01',

    purchaseHero: {
        icon: "/resources/Products/Observer_1/purchaseHero.png",
        description: "Geometric orbital sequencer",
    },

    productFeatures: [

    ],

    banner : '/resources/Products/ripplePlaceHolderPhoto.jpg',
    thumbnail : '/resources/Products/ripplePlaceHolderPhoto.jpg',
    littleIcon : '/resources/AyisenLogo/IconBlack.png',

    description : 'PHYSICS SIMULATION RHYTHM GENERATOR.',

    staticPageLink : '/products/ripple_01',
    price : undefined, 

};
