import React from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { useNavigate } from 'react-router-dom';

import api from '../../../api';
import { User } from '../../../constants/types';

import '../../../styles/General/userInfoDrop.css';

export interface UserInfoDropProps {

    hide: boolean,
    close: (e: MouseEvent) => void,
    
    user: User,

};

export const UserInfoDrop = (props: UserInfoDropProps) => {


    const navigate = useNavigate();

    const logout = async () => {

        await api.get('user/logout');
        window.location.href = '/';

    }

    return (
        
        <OutsideClickHandler
            onOutsideClick={props.close}
        >
            <div className={'userInfoDrop' + (props.hide ? ' hideDrop' : '')}>

                {/* Greeting */}
                <h3>Greetings {props.user.name.replace(/ .*/,'')},</h3>

                {/* User Info Sections */}
                <div onClick={() => navigate('/my-account')}>My Account</div>
                <div onClick={logout}>Logout</div>

            </div>

        </OutsideClickHandler>

    )


}

export default UserInfoDrop;