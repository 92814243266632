

import React from 'react';


import '../../../styles/General/integerEditor.css'

export interface IntegerEditorProps {

    value: number,
    updateNumber: (value: number) => any

}

export const IntegerEditor = (props: IntegerEditorProps) => {

    return <div className="intEditor">

        <div className='numberEditor'>
            {props.value}
        </div>

        <div className='incButtons'>

            <img 
                src='/resources/Icons/up.png'
                alt="▲"
                onClick = {() => {
                    props.updateNumber(props.value + 1)
                }}
            />
            <img 
                src='/resources/Icons/up.png'
                alt="▼"
                onClick = {() => {
                    props.updateNumber(props.value - 1)
                }}
            />

        </div>

    </div>


}

export default IntegerEditor;