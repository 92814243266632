
export interface CartItem {
    uid: string, 
    count: number,
};

export interface Cart extends Array<CartItem>{};


export interface SystemRequirementsSection {

    // Header 
    title: string,

    // Bullet Points
    bullets?: string[], 

    plainText?: string,

}


export interface SystemRequirements extends Array<SystemRequirementsSection>{};



export interface Feature {
    title: string, 
    description: string,
    icon?: string,
}


export interface RegisteredMachine {

    _id: string,
    machineId: string,
    registrationCounter: number,

}

export interface UserProduct {
    productId: string,
    purchaseId: string,
    serialNumber: string, 
    registeredMachines: RegisteredMachine[],
    productUID: string,
}

export interface AllUserProducts {[key: string]: UserProduct};



export interface ProductData {

    // Name and UID
    name: string, 
    uid: string,

    purchaseHero: {
        icon: string,
        description: string,
    },

    productFeatures: Feature[],

    // Images
    banner: string,
    thumbnail: string,
    littleIcon: string,

    // Brief Description
    description: string,

    // Links
    staticPageLink: string,

    // Pricing
    price?: number,
    salePrice?: number,

    // API Data? 
    details?: any,

    // System Requirements? (API)
    systemRequirements?: SystemRequirements,

    // FAQS
    FAQS?: {question: string, answer: string|JSX.Element}[];

    // User Manual PDF
    userManual?: string,

}


export interface ProdAddOnData {

    // Name and UID
    name: string, 
    uid: string,

    // Images
    thumbnail: string,
    littleIcon: string,

    // Brief Description
    description: string,

    // Pricing
    price?: number,
    salePrice?: number,

    // API Data? 
    details?: any,

    // User Manual PDF
    userManual?: string,

}


export interface AllProductData {
    [key: string]: ProductData
}


export interface AllAddOnData {
    [key: string]: {
        [key: string]: ProdAddOnData
    },
}



export interface User {

    _id: string,
    name: string, 
    email: string,
    avatarId?: string,
    productTrialsUsed?: string[],

}

export enum ModalStateType {
    DeleteAccount,
    Login,
    ChangeAvatar,
}

export interface ModalState {
    state: ModalStateType,
    message?: string,
}


export enum OsType {
    WINDOWS,
    MAC,
    UNIVERSAL,
}


// ------- ARTIST TESTIMONIALS -------

export interface ArtistTestimonial {

    // Name
    name: string,

    // Testimonial for Ayisen in general
    testimonial: string, 

    // Icon
    icon?: string,

    // Testimonials for specific products
    productTestimonials?: {[key: string]: {testimonial: string}};

};

export interface TeamMember {

    name: string,
    title: string,
    bio: string,
    picture: string,

}   