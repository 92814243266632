

export default () => {

    // Get host
    const hostname = window.location.hostname;

    const stagingTrackingId = "3792843720839696";
    const productionTrackingId = "439310634884724";

    // Local? 
    if (hostname.includes('localhost')) {
        return stagingTrackingId;
    }

    // Staging? 
    else if (hostname.includes('staging')) {
        return stagingTrackingId;
    }

    // Production? 
    else {
        return productionTrackingId;
    }


}

