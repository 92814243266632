import React from "react";
import ProductInstanceCard from "./ProductInstanceCard";
import SimpleDropdown from '../Dropdown/SimpleDropdown';
import { ProductData, UserProduct } from "../../../constants/types";

interface PluginInstCardProps {

    productInstance: UserProduct,
    product: ProductData,
    setLoading: (val: boolean) => any,
    includeRegisteredMachines?: boolean,
    children?: any,
}

export const PluginInstanceCard = (props: PluginInstCardProps) => {


    return <ProductInstanceCard
        product={props.product}
        productInstance = {props.productInstance}
        setLoading = {props.setLoading}
    >

        {/* Num machines registered */}
         <div className='cardDropdownWrapper'>
            <SimpleDropdown
                title = {"Registered Machines (" + (props.productInstance.registeredMachines.length + " / " + props.product.details?.machineLimit) + ")"}
            > 
                {/* Individual machine registers */}
                {props.productInstance.registeredMachines.map((machineInfo, machineIdx) => {

                    return <div className='keyValueRow'>
                        <div>{"Machine " + machineIdx + " (" + (machineInfo.machineId[0]==="M" ? "Mac" : machineInfo.machineId[0] ==="W"? "Windows" : "Unknown") + ")"}</div>
                        <div>{machineInfo.registrationCounter + " / " + props.product.details?.machineReregisterLimit}</div>
                    </div>

                })}
                
            </SimpleDropdown>
        </div>
        <br/>
        <br/>

    </ProductInstanceCard>


}

export default PluginInstanceCard;