



export default async (rawData: any, fileName: string, mimeType = 'application/octet-stream') => {

    // Create Blob
    let blob = new Blob([rawData], {type: mimeType});

    // Create an element to download from 
    let a = document.createElement('a');
    a.download = fileName;
    a.href = await window.URL.createObjectURL(blob);
    a.click();

    // Clean up
    window.URL.revokeObjectURL(a.href);

}