


import React from 'react';

import '../../../styles/General/Product/productInstanceCard.css'
import { ProdAddOnData, ProductData, UserProduct, OsType } from '../../../constants/types';
import NormalButton, { NormalButtonStyle } from '../Buttons/NormalButton';
import api from '../../../api';
import utils from '../../../utilities';


export interface ProductInstanceCardProps {

    productInstance: UserProduct,
    product: ProductData|ProdAddOnData,
    setLoading: (val: boolean) => any,
    includeRegisteredMachines?: boolean,
    children?: any,
    nameExtension?: string,

}



export const ProductInstanceCard = (props: ProductInstanceCardProps) => {


    const downloadLatestVersion = async (os: OsType) => {

        // Construct URL 
        let url = 'product/download/' + props.product.details.uid;

        // OS Extension? 
        if (os === OsType.MAC) {
            url += "/mac";
        }
        else if (os === OsType.WINDOWS) {
            url += "/windows";
        }
        else {
            url += "/universal";
        }

        // Download plugin file
        props.setLoading(true);
        await api.getDownloadPresigned(url, {}, true);
        props.setLoading(false);
        

    }

    const downloadManual = () => {

        if (!props.product.userManual)
            return;

        utils.download.downloadLocalFile(props.product.userManual, props.product.name + ".pdf");

    }

    // Find downloadables available
    let { windowsDownload, macDownload, universalDownload } = utils.product.getOssWithDownloadAvail(props.product);
    

    return <div className='productInstanceCard'>

        {/* Icon? */}
        {props.product.thumbnail && <img
            className='littleIcon'
            src={props.product.littleIcon}
            alt = ''
        />}
        <h3 className='prodInstanceTitle'>
            <div>{props.product.name.toLowerCase()}</div>
            <div className='nameExt'>{(props.nameExtension ?? "")}</div>
        </h3>
        <p className='productDescription'>{props.product.description}</p>

        {/* Values */}
        <div className='keyValueRow'>
            <div>SERIAL NUMBER</div>
            <div>{props.productInstance.serialNumber}</div>
        </div>

        {/* Child Properties */}
        {props.children}


        {/* Download  */}
        <div className='prodDownloadButtonRow'>
            {(!universalDownload && macDownload) && <>
                <div style={{height: 20}}/>
                <NormalButton 
                    text = {'Download Latest (Mac)'}
                    onClick = {() => downloadLatestVersion(OsType.MAC)}
                    style = {NormalButtonStyle.Dark1}
                    fillWidth = {true}
                />
            </>}

            {(!universalDownload && windowsDownload) && <>
                <div style={{height: 20}}/>
                <NormalButton 
                    text = {'Download Latest (Win)'}
                    onClick = {() => downloadLatestVersion(OsType.WINDOWS)}
                    style = {NormalButtonStyle.Dark1}
                    fillWidth = {true}
                />
            </>}
            
            {universalDownload && <>
                <div style={{height: 20}}/>
                <NormalButton 
                    text = {'Download Latest Version'}
                    onClick = {() => downloadLatestVersion(OsType.UNIVERSAL)}
                    style = {NormalButtonStyle.Dark1}
                />
            </>}

            {props.product?.userManual &&  <>
                <div style={{height: 20}}/>
                <NormalButton 
                    text = {'Download Manual'}
                    onClick = {downloadManual}
                    complexStyle = {{
                        style: NormalButtonStyle.HollowWithAccents,
                        background: "",
                        foreground: "var(--ayisen-forest)"
                    }}
                    fillWidth = {true}
            /></>}

        </div>


    </div>


}

export default ProductInstanceCard;