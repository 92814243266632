
import React from 'react';
import { useNavigate } from 'react-router-dom';
import constants from '../../constants';

import '../../styles/Pages/purchaseComplete.css'
import useEffectOnce from '../../utilities/hooks/useEffectOnce';
import NormalButton, { NormalButtonStyle } from '../General/Buttons/NormalButton';
import GenericPageWrapper from './GenericPageWrapper';

export interface PurchaseCompletePageProps {

    clearCart: () => any,

}

export const PurchaseCompletePage = (props: PurchaseCompletePageProps) => {

    const navigate = useNavigate();

    useEffectOnce(() => {

        // Ping Google Conversions API
        window.gtag('config', 'AW-10782576559');
        window.gtag('event', 'conversion', {'send_to': 'AW-10782576559/Q-C1CIzeqc4DEK-fxJUo'});

        // Clear cart! 
        props.clearCart();

    });


    return <GenericPageWrapper><div className='pCompletePage fadeQuick' id="purchaseCompletePage">


        {/* Body */}
        <div className='pCompleteBody'>

            {/* Icon */}
            <img className='purchaseCompleteIcon' src='/resources/Icons/peace.png' alt='AYISEN'></img>

            <h1>Thank you :)</h1>

            <p className='subText1'>
                A confirmation has been sent to your email with instructions for downloading and installing your purchases. You can also manage them from <a href='my-account'>My Account</a>.
            </p>

            <p className='subText2'>
                If you don't receive an email within 10 minutes, please <a href={"mailto:" + constants.general.contactEmail}>contact us</a>.
            </p>

            <NormalButton 
                text = "Continue shopping"
                onClick = {() => navigate('/')}
                complexStyle = {{
                    style: NormalButtonStyle.HollowWithAccents,
                    background: "",
                    foreground: "var(--ayisen-midnight)",
                }}
                customXPad = {90}
            />

        </div>
        
    </div></GenericPageWrapper>


}

export default PurchaseCompletePage;