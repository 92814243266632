
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import api from '../../api';
import { ModalState } from '../../constants/types';

import '../../styles/Modals/changeAvatar.css';
import utils from '../../utilities';
import NormalButton, {NormalButtonStyle} from '../General/Buttons/NormalButton';
import StringInput from '../General/String/StringInput';



export interface ChangeAvatarProps {
    setModalState: (state: ModalState|null) => any,
    fetchUserData: () => any,
}

export const ChangeAvatarModalBody = (props: ChangeAvatarProps) => {

    const setAvatar = async (avatarId: string) => {

        // Post
        const res = await api.post('user/update', {diff: {avatarId}}, {}, true);

        if (res && res.success) {
            props.fetchUserData();
            toast.info("Your avatar has been updated!");
            props.setModalState(null);
        }

    }


    return <div className='changeAvatarBody'>

        <h2>Select your avatar</h2>

        <div className='imgs'>
            {utils.user.getAllAvatars().map((avatarInfo, idx) => {

                return <img
                    className='avatarIcon'
                    key = {idx}
                    src = {avatarInfo.icon}
                    onClick = {() => setAvatar(avatarInfo.id)}
                />

            })}

        </div>
        

    </div>


}



export default ChangeAvatarModalBody;