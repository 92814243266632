import { TeamMember } from "./types";

const team: TeamMember[] = [

    {
        name: "Steven M.",
        title: "Visiion and Sound",
        bio: "",
        picture: "/resources/Team/Steven.png",
    },
    {
        name: "Marcel L.",
        title: "Visiion and Tech",
        bio: "",
        picture: "/resources/Team/Marcel.jpeg",
    }

];

export default team;