

import axios from 'axios';
import { toast } from 'react-toastify';

import downloadBlobRes from './utilities/downloadBlobRes';
import downloadURLRes from './utilities/downloadURLRes';
import getAPIUrl from './utilities/getAPIUrl';

const API_URL = getAPIUrl();


let defaultConfig = {
    withCredentials: true
}

// defaultConfig = {}



const performRequest = async (callback: any, showErrors = false, useRawResponse = false, skipAuth = false) => {

    try {

        const res = await callback();

        // Raw response handling? 
        if (useRawResponse) {
            return {
                success: true, 
                raw: res,
            }
        }

        // General response handling
        if (!res || !res.data)
            return {
                success: false, 
                message: 'No data found.',
            };

        return {
            success: true,
            ...res.data,
        };

    }

    catch (e: any) {

        // Get any meaningful status codes.
        // console.log("Request Error: ", e);

        // Code
        let errorCode = 500;
        if (e.response && e.response.status)
            errorCode = e.response.status;

        // Auth error: login
        // #Tag: Enhancement @Marcel: Port this to use the login modal
        // if (errorCode === 401 && !skipAuth) {
        //     window.location.href = '/login' + window.location.pathname;
        //     return;
        // }


        // Data 
        let data: any = { };
        if (e.response && e.response.data) 
            data = e.response.data;
        
        // Blob data parsing
        if (data instanceof Blob) 
            data = JSON.parse(await data.text());
        

        // Message 
        let errorMessage = undefined;
        let uiMessageSent = false;
        if (data && data.message) {
            errorMessage = data.message;
            uiMessageSent = true;
        }

        // Error showing
        if (showErrors && errorMessage)
            toast.error(errorMessage);

        return {
            success: false, 
            errorCode: errorCode, 
            message: errorMessage, 
            uiMessageSent: uiMessageSent,
            data: data,
        }

    }

}



export default {


    // GET
    get: async (endpoint: string, config = {}, showErrors = true, skipAuth = false) => {

        const requestCallback = async () => {
            return await axios.get(API_URL + endpoint, {...defaultConfig, ...config} );
        }

        return performRequest(requestCallback, showErrors, false, skipAuth);


    },

    // POST
    post: async (endpoint: string, data = {}, config = {}, showErrors = false) => {

        const requestCallback = async () => {
            return await axios.post(API_URL + endpoint, data, {...defaultConfig, ...config} );
        }

        return performRequest(requestCallback, showErrors);

    },

    getDownloadPresigned: async (endpoint: string, config = {}, showErrors = false) => {

        const requestCallback = async () => {
            return await axios.get(API_URL + endpoint, {...defaultConfig, ...config} );
        }

        // Perform
        const res = await performRequest(requestCallback, showErrors, false);

        if (!res || !res.url || !res.success) {
            // #Tag: BugNote: Handle errors? 
            return;
        }

        // Download from the url!
        return downloadURLRes(res.url);

    },

    
    
    getDownload: async (endpoint: string, fileName: string, performDownload = true, config = {}, showErrors = false) => {

        const requestCallback = async () => {
            return await axios.get(API_URL + endpoint, {...defaultConfig, responseType: 'blob', ...config} );
        }

        // Perform
        const res = await performRequest(requestCallback, showErrors, true);

        // Download
        if (res && res.success && res.raw && res.raw.data && performDownload)
            await downloadBlobRes(res.raw.data, fileName);
        else 
            return res;

    }

    


}