
import React from 'react';


import '../../../styles/General/Random/circleImageGrid.css'


export interface ImageData {
    icon: string,
    label?: string,
    onClick?: () => any,
}

export interface CircleImageGridProps {

    itemDims?: number|string,

    images: ImageData[],

}

const wrapColorNames = ['blueWrap', 'sunsetWrap', 'goldWrap'];

export const CircleImageGrid = (props: CircleImageGridProps) => {

    const itemDims = props.itemDims ?? 200;
    

    return <div className='circleImageGrid'>

        {props.images.map((image, idx) => {

            const wrapClass = wrapColorNames[idx % wrapColorNames.length];
            
            // Grid Item
            return <div 
                className={"circleWrapper " + (wrapClass)} 
                style={{ width: "100%", maxWidth: itemDims}} 
                key={idx}
            >

                {/* Image Wrapper (centering and sizing) */}
                <div className='imgWrapper'>
                    <img 
                        src = {image.icon}
                        alt = ''
                        onClick={image.onClick ?? (() => {})}
                    />
                    
                </div>

                {image.label && <p className='imgLabel'>
                    {image.label}
                </p>}

            </div>
        })}



    </div>


}

export default CircleImageGrid;