
import React from "react";

import '../../../styles/General/Carousels/spotlightFeature.css';


interface SpotlightWrapper1Props {

    spotlightItems: {[key: string]: {elem: JSX.Element, title?: string, background?: string, backgroundColor?: string}},

    activeIdx: number, 
    setActiveIdx: (val: number) => any,

}


export const SpotlightWrapper1 = (props: SpotlightWrapper1Props) => {


    // Get the longeset title
    let longestTitle = "";
    for (let key of Object.keys(props.spotlightItems)) {

        const curTitle = props.spotlightItems[key]?.title
        if (curTitle) {
            
            if (curTitle.length > longestTitle.length) {
                longestTitle = curTitle;
            }
        }
    }


    // Get the percent we've moved through (for header animations)

    // Percent through indexing
    const percentPerItem = (1 / Object.keys(props.spotlightItems).length) * 100;

    // Middle value's index
    const middleIdx = Math.floor((Object.keys(props.spotlightItems).length - 1) / 2);

    // Offset from the middle Idx 
    const offsetFromMiddleIdx = middleIdx - props.activeIdx;

    // Percentage this is equivalent to 
    const offsetPercet = percentPerItem * offsetFromMiddleIdx;

    // Offset the selector row by the percent we're through, offset around 50% (which is the default)
    const percentOffset = (offsetPercet);


    return <div className="spotlightWrapper1">        

        <div
            className="spotlightTopSpacer"
        />

        <div
            className='spotlightItemBackgroundWrapper'
        >

            {/* Currently Active Background */}
            {Object.values(props.spotlightItems).map((item, idx) => {

                const styles: any = { };
                if (item?.background) {
                    styles.backgroundImage = "url(" + item.background + ")";
                }
                if (item?.backgroundColor) {
                    styles.backgroundColor = item.backgroundColor;
                }

                return <div 
                    key = {idx}
                    className={"spotlightBack" + ((idx === props.activeIdx) ? " spotlightActive" : "")}
                    style = {styles}
                />

            })}

        </div>

        {/* Selector */}
        <div className="innerAligner" style={{width: "60%"}}>
            <div className="spotlightSelector" style = {{left: percentOffset + "%"}}>

                {Object.keys(props.spotlightItems).map((itemName, idx) => {


                    return <div
                        className = {(props.activeIdx === idx) ? "activeSpotlightSelection" : ""}
                        key = {idx}
                        onClick = {() => props.setActiveIdx(idx)}
                    >

                        {itemName}

                    </div>

                })}

            </div>
        </div>


        {/* Header Wrapper */}
        <div className="spotlightHeaderWrapper">

            {/* Title sizer (not visible) */}
            <div className="spotlightHeadSizer" style={{opacity: 0}}>

                <h2>
                    {longestTitle}
                </h2>


            </div>

            {/* Currently Active Title */}
            {Object.values(props.spotlightItems).map((item, idx) => {

                return <div 
                    key = {idx}
                    className={"innerAligner spotlightHeader" + ((idx === props.activeIdx) ? " spotlightActive" : "")}
                    style={{maxWidth: 1000}}
                >

                    {item.title && <h2>{item.title}</h2>}

                </div>

            })}



        </div>

        

        

        {/* Items */}
        <div className="bodySection">
            {Object.values(props.spotlightItems).map((item, idx) => {

                return <div 
                    key = {idx}
                    className={"spotlightItem" + ((idx === props.activeIdx) ? " spotlightActive" : "")}
                >
                    {item.elem}

                </div>
            
            })}

        </div>

        <div
            className="spotlightBottomSpacer"
        />

        


    </div>


}

export default SpotlightWrapper1;