

export default () => {

    // Get host
    const hostname = window.location.hostname;

    // Local? 
    if (hostname.includes('localhost')) {
        return 'http://localhost:8080/';
    }

    // Staging? 
    else if (hostname.includes('staging')) {
        return 'https://staging.api.ayisen.com/';
    }

    // Production? 
    else {
        return 'https://api.ayisen.com/';
    }


}