import React from 'react'

import "../../../styles/General/Image/rotatableImage.css"

export interface RotImageProps {

    src: string,
    angle: number,
    bottomEdgeColor?: string,

}


export const RotatableImage = (props: RotImageProps) => {

    let bottomStyles: any = {}
    if (props.bottomEdgeColor)
        bottomStyles.backgroundColor = props.bottomEdgeColor;


    return <div className="rotatableImgWrapper"
        style={{transform: "perspective(4000px) rotate3d(1, 0, 0, " + props.angle +  "deg)"}}
    >
        <img 
            src = {props.src}
            alt = ''
        />
        <div
            className="bottomEdge"
            style={bottomStyles}
        />
    </div>


}

export default RotatableImage;