
import { AllAddOnData, ProdAddOnData } from "../../constants/types";

/*
    Flatten out addons, to not be nested by product

*/
export const flattenAddOns = (addOns: AllAddOnData) => {

    let allAddOns: {[key: string]: ProdAddOnData} = {}

    for (let prodAddOns of Object.values(addOns)) {

        allAddOns = {
            ...allAddOns, 
            ...prodAddOns,
        };

    }

    return allAddOns;

}

export default flattenAddOns;