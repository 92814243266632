
import React, { useEffect, useState } from 'react';

import '../../../styles/General/Product/featureCard.css'
import { Feature, SystemRequirements } from '../../../constants/types';



export interface FeatureCard2Props {

    feature: Feature,

}

const FeatureCard2 = (props: FeatureCard2Props) => {


    return <div className='featureCard2'>

        {/* Icon? */}
        <h2>{props.feature.title}</h2>
        <p>{props.feature.description}</p>

    </div>


}

export default FeatureCard2;