import React from 'react';

import "../../../styles/Pages/legal.css";



const docConfig = [

    {
        header: "", 
        paragraphs: [
            <div>
                Thank you for your purchase. We hope you are happy with your purchase, however, if you are not completely satisfied with you purchase for any reason, you may return it to us for a full refulnd only. Please see below for more information on our return policy.
            </div>
        ],
    },
    {
        header: "Returns",
        paragraphs: [
            <div>
                All returns must be issued within fourteen (14) days of the purchase date.
            </div>
        ]
    },
    {
        header: "Return Process",
        paragraphs: [
            <div>
                To return an item, please contact us with your refund / return request, as well as the reason for your request.
            </div>,
            <div>
                For more information on contacting us, please see the sections below. 
            </div>
        ]
    },
    {
        header: "Refunds",
        paragraphs: [
            <div>
                After receiving your refund / return request, we will process your return. Please allow for fourteen (14) days from the receipt of your item to process your return. Refunds may take 1-2 billing cycles to appear on your credit card statement, depending on your credit card company.
            </div>
        ]
    },
    {
        header: "Exceptions",
        paragraphs: [
            <div>
                For defective or damaged products, please contact us at using the contact details below to arrange a refund or exchange. 
            </div>
        ]
    },
    {
        header: "Questions",
        paragraphs: [
            <div>
                If you have any questions concerning our return policy, please contact us at <a href="mailto:ayisenmain@gmail.com">ayisenmain@gmail.com</a>
            </div>
        ]
    }

    


]





export const Refunds = () => {



    return <div className='plainDocumentPage fadeQuick' id="plainDocumentPage">

        <div className='innerAligner plainDocumentTextContainer'>
            

            <h1>REFUNDS</h1>

            <div className='lastUpdatedText'>Last updated August 2nd, 2022</div>

            {docConfig.map((docSection, idx) => {

                return <div className='plainDocumentSection'>

                    <h3>{docSection.header}</h3>

                    {docSection.paragraphs.map(e => {return e})}
                
                </div>

            })}
          
        </div>

</div>

}

export default Refunds;