
import ProductPage from './ProductPage';
import MyAccount from './MyAccount';
import LoginOps from './LoginOps';
import About from './About';
import Legal from './Legal';
import TextAndButtonPage from './TextAndButton';

export default {
    ProductPage,
    MyAccount,
    LoginOps,
    About,
    Legal,
    TextAndButtonPage,
}